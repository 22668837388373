.gallery {
  article {
    background: $white;
    box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);
    margin-bottom: 20px;
    header {
      height: 100%;
      position: relative;
      float: left;
      width: 40%;
      transition: all 0.1s ease;
      &:hover,
      &:focus {
        opacity: 0.8;
      }
      img {
        width: 100%;

      }
    }
    .post-meta {
      font-size: 14px;
      color: #506373;

      span i.fa,
      time i.fa {
        position: relative;
        top: -1px;
        font-size: 12px;
        margin-right: 2px;
        color: #8fa2b3;
      }

      time {
        margin-left: 10px;

      }

    }
    .entry-summary {
      padding: 25px;
      float: right;
      width: 60%;
      &.full {
        width: 100%;
        float: none;
      }
      .read_more {
        color: rgba(0, 0, 0, 0.5);

        &:hover {
          color: rgba(0, 0, 0, 0.8);
        }
      }
      a {
        h3 {
          line-height: 26px;
          font-size: 21px;
          margin-top: 0;
          font-weight: bold;
          color: #000;
          transition: color .2s ease;

          &:hover,
          &:active {
            color: $link;
            text-decoration: none;
          }
        }

        &:hover,
        &:active {
          text-decoration: none;
        }
      }
      hr {
        margin: 10px 0;
      }
    }
  }
}

.single {
  .entry-header  {
    overflow: hidden;
  }
  .single-header {
    width: 100%;
    float: left;
    //overflow: hidden;
    position: relative;

    img {
      max-width: 100%;
      height: auto;
    }

    .logo {
      width: 200px;
      height: 100px;
      background: #fff;
      border-radius: 35px;
      position: absolute;
      bottom: -50px;
      left: 50%;
      margin-left: -100px;
      text-align: center;

      img {
        top: 50%;
        margin-top: -65px;
        position: relative;
        max-height: 50px;
        max-width: 150px;
      }
    }
  }

  .score {
    a {
      color: #000;
      font-size: 14px;
    }
  }

  p.onliner {
    margin: 20px 0px;
    padding: 10px;
    font-weight: 300 !important;
  }

  .sticky_button {
    display: none;
  }

  .entry-header {
    .col-xs-6.last {
      border-left: 1px solid #eee;
    }
  }

  .specs {
    h3 {
      font-weight: bold;
      font-size: 28px;
      margin: 0px 0px 10px 0px;
    }

    .aanbevelingen {
      margin-bottom: 20px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
    }

    .fa-check {
      color: #107c17;
    }

    .last {
      i {
        float: right;
      }
    }

    .line {
      margin-bottom: 5px;

      .name {
        float: left;
      }

      span {
        float: right;
      }

      .value, .rating {
        float: right;
      }
    }
  }

  .content {

    article {

      .entry-title {
        margin-top: 0;
        font-weight: bold;

        span {
          font-weight: 300;
        }
      }

      .post-meta {
        font-size: 14px;
        color: #506373;

        span i.fa,
        time i.fa {
          position: relative;
          top: -1px;
          font-size: 12px;
          margin-right: 2px;
          color: #8fa2b3;
        }

        time {
          margin-left: 10px;
        }

      }
      .entry-content {
        margin-top: 20px;

        img {
          width: 100%;
          height: auto;
        }

        // Remove adblocker
        a {
          display: inline !important;
        }

        .responsive-table {

          table {

            tbody {

              tr {

                td {

                  .button {
                    display: inline-block !important;
                    font-size: 15px;
                    padding: 5px;
                  }

                  .ribbon {
                    display: none;
                  }

                  &.aanbevolen .logo {
                    padding-top: 0;
                  }

                  .rating {
                    font-size: 15px;
                  }

                  .price, .score {
                    font-size: 30px;
                  }

                }

              }

            }

          }

        }

      }

    }

    .type-post {
      background-color: $white;
      box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);
      padding: 15px;

      .back {
        padding: 10px 0;
        margin-top: 40px;
        border-top: 1px solid $lightgrey;
        border-bottom: 1px solid $lightgrey;
      }

      .author {
        margin-top: 10px;
        display: inline-block;

        img {
          width: 100%;
          margin-top: 10px;
          height: auto;
        }

        h4 {
          font-weight: $f-bold;
          color: $link;
        }

        @include breakpoint(md) {

          img {
            max-width: 90px;
            margin-bottom: 10px;
          }

        }

      }

      .share-box {
        margin: 20px -15px;
        background-color: $brand-primary;
        color: $white;
        min-width: 100%;
        width: auto;
        padding: 20px 15px;

        h4 {
          float: left;
          display: block;
          margin: 5px 0px;
        }

        .boxed-icons {
          float: right;

          a {
            background-color: $link;
            padding: 10px;
            color: #fff;
            transition: all 0.2s linear;
            text-decoration: none;

            &:hover {
              background-color: lighten($link, 10%);
            }

          }

        }

      }

    }

    .type-aanbieder {

      .entry-header {
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);
        padding: 30px;

        header {
          text-align: center;

          a {
            display: block;
          }

          img {
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
          }

        }

        .rating {
          text-align: center;
          padding-top: 10px;
          //border-top: 1px solid #eee;
          margin-top: 15px;
        }


        .meta {
          font-size: 12px;
          line-height: 19px;
        }

        .w_author {
          border-top: 1px solid #f1f5f7;
          padding-top: 15px;
          margin-top: 15px;
        }

        p {
          font-weight: bold;
          margin-bottom: 0px;
          line-height: 1.2;
        }

        .ta_rating {
          font-weight: bold;
          font-size: 21px;
          margin-top: -3px;
        }

        .name {
          font-weight: bold;
        }

        .score {
          text-align: center;

          .waardering {



            &.last {
              margin-top: 10px;
            }

            p {
              line-height: 1.5;
            }


          }

          p {
            margin-bottom: 0;
          }

        }

        .price {
          font-weight: 700;
          margin-bottom: 25px;
          font-size: 25px;
          span {
            color: $link;
          }
        }

        .voordelen,
        .checklist {
          margin-top: 20px;
          position: relative;

          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            margin-bottom: 0;
          }

          .leden {
            text-align: left;
            border: 1px solid #579712;
            float: left;
            border-radius: 20px;
            padding: 3px 10px;
            box-shadow: 0px 4px 5px rgba(57, 151, 18, 0.2);
            color: #579712;
            font-weight: bold;
            margin-top: 10px;
            font-size: 14px;
          }

          li {
            font-size: 0.875em;
            line-height: 1.2;
            margin-bottom: 15px;
            padding-left: 30px;
            text-align: left;

            &:before {
              content: "\f00c";
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              position: absolute;
              left: 0;
              font-size: 18px;
              color: #107c17;
              vertical-align: middle;
            }

            &:last-child {
              margin-bottom: 0;
            }

          }

        }

        .moneyback {
          margin-top: 26px;
          font-size: 15px;
          color: $brand-primary;

          p {
            font-weight: normal;
          }

        }

      }

      .results_total {
        font-size: 14px;
        font-weight: normal;
      }


      .awards {
        width: 100%;
        box-sizing: border-box;
        border-top: 1px solid $lightgrey;
        border-bottom: 1px solid $lightgrey;
        padding-bottom: 15px;
        margin-top: 20px;
        padding-top: 20px;

        h3 {
          margin-top: 10px;
        }

        p {
          span {
            font-weight: bold;
          }
        }

        .award_row {
          text-align: center;

          img {
            width: 33%;
            height: auto;
            float: left;
            padding: 10px;
          }

        }

      }

      .author {

        h3 {
          margin-top: 10px;
        }

        img {
          width: 100%;
          margin-top: 10px;
        }

        h4 {
          font-weight: $f-bold;
          color: $link;
        }

      }

      .entry-content {
        position: relative;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);
        padding: 15px;

        .back {
          margin-bottom: 10px;
          width: 100%;
          float: left;
          //display: block;
          //position: absolute;
          //top: 23px;
          //right: 20px;
          color: #aeaeae;
        }

      }

      .cta-top,
      .cta-bottom {
        text-align: center;

        .button {
          display: block;
        }

      }

      .cta-top {
        padding: 0;
        padding-top: 30px;
        border-top: 1px solid $lightgrey;
        margin-top: 15px;
      }

      .cta-bottom {
        padding: 30px 0 15px;
      }

    }

    .main {
      .type-post {
        header {
          height: auto;
          //padding-bottom: 50%;
          position: relative;
          margin: 20px -15px 0 -15px;
          img {
            height: auto;
            width: 100%;
          }
        }
      }
      .related-title {
        border-top: 1px solid $lightgrey;
        margin-top: 20px;
        padding-top: 40px;
      }
      .related {
        header {
          margin: 0;
        }
      }
    }
  }
}

.no-result {
  background: $white;
  border: 1px solid $lightgrey;
  padding: 15px;
  h3 {
    margin: 0;
    line-height: 1.3;
  }
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
  .single {
    .content {
      .type-aanbieder {
        .entry-content {
          margin-top: 15px;
        }
      }
    }
  }
  .gallery {
    article {
      margin-bottom: 15px;
    }
  }
}

@include breakpoint(md) {
  .single {

    .specs {
      .col-md-4 {
        margin-bottom: 20px;
        border-bottom: 1px solid #eee;
        padding-bottom: 20px;
      }

      .aanbevelingen .line {
        width: 100%;
        display: block;
        position: relative;
        float: left;
      }
    }
    .content {

      article {

        .entry-title {
          font-size: 30px;
        }

        .entry-content .responsive-table table tbody tr td {

          .rating {
            font-size: 16px;
          }

          a {
            display: block;
          }

          .button {
            font-size: 16px;
            padding: 10px;
          }

        }

      }
      .type-aanbieder {

        .author {

          img {
            max-width: 90px;
            margin-bottom: 10px;
          }

        }

        .awards {
          border-bottom: 0;
          padding-bottom: 0;

          .award_row {

            img {
              width: 33.33;
              max-width: 120px;
            }

          }

        }

      }

    }

  }

}

@include breakpoint(sm) {
  .gallery {
    article {
      height: auto !important;
      header {
        width: 100%;
        padding-bottom: 50%;
        height: auto !important;
      }
      .entry-summary {
        width: 100%;
      }
    }
  }
  .single {
    .content {
      .main {
        .type-post {
          header {
            height: auto;
            padding-bottom: 65%;
          }
          .author {

          }
        }
      }
      article {
        .entry-title {
          font-size: 25px;
          text-align: center;
          margin-bottom: 15px;
        }
        .entry-content {
          img,
          iframe {
            width: 100%;
            max-width: 100%;
            height: auto;
          }
        }
      }
      .type-aanbieder {
        .entry-content {
          .back {
            position: relative;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}

.entry-header_hook {
  background-color: $white;
  border-top: 1px solid $lightgrey;
  border-bottom: 1px solid $lightgrey;
  margin-top: 20px;
  padding-top: 20px;

  .cta-top {
    border-top: 1px solid $lightgrey;
    border-bottom: 1px solid $lightgrey;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 10px;

    a.button {
      padding: 10px 20px;
    }
  }

  .results_total {
    font-size: 14px;
    font-weight: normal;
  }


  header {
    text-align: center;
    padding-bottom: 0px !important;
    margin-top: 0px !important;

    img {
      max-height: 100%;
      max-width: 70%;
      width: auto;
      height: auto;
    }
  }
  .rating {
    text-align: center;
    padding-top: 10px;
    margin-top: 15px;
  }
  .meta {
    font-size: 12px;
    line-height: 19px;
  }
  .w_author {
    margin-top: 15px;
  }
  p {
    font-weight: bold;
    margin-bottom: 0px;
    line-height: 1.2;
  }
  .ta_rating {
    font-weight: bold;
    font-size: 21px;
    margin-top: -3px;

  }
  .name {
    font-weight: bold;
  }
  .score {
    text-align: center;
    p {
      margin-bottom: 0;
    }
  }
  .price {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 25px;
    span {
      color: $link;
    }
  }
  .voordelen,
  .checklist {
    position: relative;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      margin-bottom: 0;
    }
    li {
      font-size: 16px;
      line-height: 1em;
      margin-bottom: 15px;
      padding-left: 30px;
      text-align: left;
      &:before {
        content: "\f00c";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 0;
        font-size: 18px;
        color: $green;
        vertical-align: middle;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
}

@include breakpoint(md) {
}

@include breakpoint(sm) {
  .single {

    #breadcrumbs {
      display: none;
    }

    .single-header {
      .logo {
        border-radius: 35px;
        bottom: -70px;
        img {
          margin-top: -70px;
        }
      }
    }
    
    .sticky_button {
      display: block;
      position: fixed;
      border-radius: 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      text-align: center;
      font-weight: bold;
      z-index: 99999999999;
      padding: 15px 0px;
      font-size: 26px;
      box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.3);
    }

    .content {
      .type-post {
        .share-box {
          h4 {
            margin-bottom: 10px;
          }
          .boxed-icons {
            float: left;
            width: 100%;
            a {
              float: left;
              margin-right: 5px;
              margin-bottom: 5px;
              text-align: center;
            }
          }
        }
        .post-meta {
          .author {
            display: block;
          }
          time {
            margin-left: 0;
          }
        }
      }
      .type-aanbieder {
        .entry-header {
          header {
            text-align: center;
          }
          .pull-right {
            float: none !important;
          }
          .price {
            text-align: center;
            margin-top: 17px;
            font-size: 22px;
          }
          .voordelen,
          .checklist {
            width: 220px;
            margin: 0 auto;
            float: none;
            margin-top: 30px;
            li {
              margin-bottom: 13px;
            }
          }

        }
      }
    }
  }

  .entry-header_hook {
    header {
      text-align: center;

      img {
        max-width: 70% !important;
      }
    }
    .pull-right {
      float: none !important;
    }
    .price {
      text-align: center;
      margin-top: 17px;
      font-size: 22px;
      margin-bottom: 0px;
    }
    .voordelen,
    .checklist {
      width: 220px;
      margin: 0 auto;
      float: none;
      margin-top: 30px;
      li {
        margin-bottom: 13px;
      }
    }

  }
}
