ribbpage-template-template-compare2 {
  .responsive-table table tbody tr td .box {
    background-color: #FFA801;
    color: white;
    &:after {
      border-top-color: #FFA801;
    }
  }
}

.page-template-template-compare3 {

  .responsive-table table tbody tr td .button {
    background: #f8931f;
    border:0;
    &:hover {
      background: #bd6f16;
    }
  }
}

.responsive-table {
  margin-bottom: 20px;
  box-shadow: 0 3px 6px 0 rgba(71, 73, 96, .1);

  table {
    width: 100%;
    background-color: $white;

    thead {
      background-color: $brand-primary;
      border: 1px solid $brand-primary;
      display: none;

      tr {
        height: 50px;
        th {
          text-align: center;
          background-image: none;
          cursor: default;
          padding: 4px;
          color: $white;
          font-weight: bold;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $lightgrey;

        &.aanbevolen {
          .logo {
            //padding-top: 35px;
            img {
              width: 100%;
              height: auto;
            }
          }
        }

        .leden {
          text-align: left;
          border: 1px solid #579712;
          float: left;
          border-radius: 20px;
          padding: 3px 10px;
          box-shadow: 0px 4px 5px rgba(57, 151, 18, 0.2);
          color: #579712;
          font-size: 14px;
          font-weight: bold;
        }

        td {
          text-align: center;
          position: relative;
          padding: 15px;

          &.table_logo {
            width: 25%;
          }

          &.table_review {
            width: 25%;

            .rating {
              margin-bottom: 10px;

              i {
                font-size: 26px;
              }
            }
          }

          .logo {

            a {
              display: block;
            }

            img {
              //width: 200px !important;
              max-width: 100%;
              height: auto;
            }

          }

          .discount {
            margin-top: 5px;
            border-radius: 4px;
            padding: 5px;
            line-height: 1.56;
            font-size: 15px;
            cursor: pointer;
            position: relative;

            &:hover {
              -webkit-animation: .2s ease 0s normal forwards 1 opacity;
              animation: .2s ease 0s normal forwards 1 opacity;
            }

            @keyframes opacity {
              0% {
                opacity: 1;
              }
              66% {
                opacity: 0.9;
              }
              100% {
                opacity: 0.9;
              }
            }

            @-webkit-keyframes opacity {
              0% {
                opacity: 1;
              }
              66% {
                opacity: 0.9;
              }
              100% {
                opacity: 0.9;
              }
            }

            .tooltip {
              width: 120px;
              background-color: #555;
              color: #fff;
              text-align: center;
              border-radius: 6px;
              padding: 5px 0;
              position: absolute;
              z-index: 1;
              bottom: 125%;
              left: 50%;
              margin-left: -60px;
              display: none;
            }

            .tooltip::after {
              content: "";
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: #555 transparent transparent transparent;
            }

          }

          .voordelen {

            ul {
              position: relative;
              margin: 0;

              li {
                list-style-type: none;
                font-size: 14px;
                text-align: left;
                line-height: 26px;
                margin-bottom: 5px;

                &:before {
                  content: "\f00c";
                  display: inline-block;
                  font: normal normal normal 14px/1 FontAwesome;
                  font-size: inherit;
                  text-rendering: auto;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;
                  left: 12px;
                  margin-top: 2px;
                  font-size: 21px;
                  color: #107c17;
                  vertical-align: middle;
                }

              }

            }

          }

          a.voordelen_wrap {
            text-decoration: none;
            color: #000;
          }

          .score {
            display: inline;
            font-weight: bold;
            border-left: 1px solid #eee;
            padding-left: 10px;
            margin-left: 10px;
            font-size: 16px;
          }

          .price {
            font-family: Arial;
            font-weight: inherit;
            font-size: 40px;
            color: $brand-primary;
            font-style: inherit;
            line-height: inherit;
          }

          .button {
            display: block;
            font-size: 16px;
            line-height: 1.4;
            height: auto;
            padding: 10px;
            //margin-bottom: 5px;
            border-radius: 5px 5px 0px 0px;
          }

          .subline_button {
            //background: #222222;
            //color: #fff;
            float: left;
            width: 100%;
            padding: 5px 5px;
            text-align: center;
            font-weight: bold;
            border: 1px solid #579712;
            color: #579712;
            border-top: 0px;
            border-radius: 0px 0px 5px 5px;
            font-size: 14px;

            &:hover {
              background: #579712;
              color: #fff;
              text-decoration: none;
            }
          }

          .box {
            display: none;
            background-color: #f1f1f1;
            color: #3a3a3a;
            font-size: 13px;
            letter-spacing: 1px;
            max-width: 230px;
            padding: 8px;
            position: relative;
            text-align: center;
            line-height: 1.2;
            margin: 0 auto 22px;
            border-radius: 5px;

            p {
              margin-bottom: 0;
            }

            &:after {
              content: "";
              border: solid transparent;
              border-top-color: #f1f1f1;
              height: 0;
              width: 0;
              left: 10%;
              position: absolute;
              pointer-events: none;
              border-width: 11px;
              margin-left: 0;
              bottom: -22px;
            }

          }

          .ribbon {
            width: 190px;
            height: 30px;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
            padding-left: 15px;
            position: absolute;
            left: -9px;
            top: 10px;
            color: $white;
            background: $green;
            &:before, &:after {
              content: "";
              position: absolute;
            }
            &:before {
              height: 0;
              width: 0;
              top: -8.5px;
              left: 0.1px;
              border-bottom: 8px solid darken($green, 10);
              border-left: 8px solid transparent;
            }
            &:after {
              height: 0;
              width: 0;
              right: -14.5px;
              border-top: 15px solid transparent;
              border-bottom: 15px solid transparent;
              border-left: 15px solid $green;
            }
          }

          .hidden-md {
            display: none;
          }

        }

      }

    }

    tfoot {
      display: none;
    }

  }

  .table_footer {
    width: 100%;
    background-color: #fff;
    //border: 1px solid #ddd;
    border-top: 0;
    text-align: center;
    padding: 15px;
  }

}

table.sortable th:not(.sorttable_sorted):not(.sorttable_sorted_reverse):not(.sorttable_nosort):after {
  content: " \25B4\25BE"
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
  .responsive-table {
    table {
      tbody {
        tr {
          td {
            .price {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(md) {
  .responsive-table {
    table {
      tbody {
        tr {
          td {
            &.price_td {
              padding: 15px 5px;
            }
            .button {
              display: inline-block !important;
            }
            a {
              display: block;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(sm) {
  .responsive-table {
    table {
      thead {
        display: none;

        tr {
          th {
            width: 100%;
            float: left;
            padding: 10px;
            &.sorttable_nosort {
              display: none;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            width: 100%;
            float: left;
            padding: 15px 9px;
            .voordelen {
              ul {
                //width: 220px;
                margin: 0 auto;
              }
            }

            .button {
              width: 100%;
            }
            &.table_logo, &.table_voordelen, &.table_review {
              width: 100%;
            }
            &.table_logo {
              text-align: center;

              img {
                width: auto !important;
                max-width: 100%;
              }
            }
            .price {
              font-size: 33px;
            }
            .hidden-md {
              display: inline;
            }
            &.price_td {
              padding: 10px 9px;
            }
            &.score_td {
              padding: 5px 9px;
            }
          }
        }
      }
    }
  }
}
