.page-header {
  padding: 30px 0px 10px 0px;
  margin: 0;
  border: none;
  background-color: $white;
  h1 {
    line-height: 1;
    font-weight: 700;
    margin: 0;
    font-size: 32px;
    padding: 0;
    span {
      color: $link;
    }
  }
  .page-title {
    margin-bottom: 20px;
    span {
      color: $brand-primary;
      margin-left: 15px;
      vertical-align: middle;
    }
    img {
      width: 50px;
      height: auto;
    }
  }

  .page-summary {
    margin-top: 10px;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.content {
  padding: 20px 0px 60px 0;
  width: 100%;
  float: left;

  .bottom_links {
    float: left;
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);

    h3 {
      margin-top: 0;
      font-weight: bold;
      margin-bottom: 15px;
      width: 100%;
      float: left;
    }

    p {
      width: 100%;
      float: left;
    }

    ul {
      float: left;
      width: 100%;
      list-style-type: none;
      padding-left: 0;

      li {
        padding-left: 0;
        margin-bottom: 5px;

        i {
          line-height: 1.6;
          position: absolute;
        }

        span {
          padding-left: 15px;
          display: inline-block;
        }

      }

    }
  }

}

.page,
.page-template-template-compare-choice {

  .content-box,
  .entry-content {

    .entry-header_hook {

      .cta-top {
        padding-bottom: 0;
        border-bottom: 0;
      }

    }

  }

}

.main {
  padding-right: 60px;
}

.rating {
  i {
    color: #ffb400;
  }
}

.landingspage {

  .entry-header {
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(71,73,96,.09);
    margin-bottom: 10px;

    &.imgLiquid_ready {
      padding-bottom: 45%;
    }

    img {
      width:100%;
      height: auto;
    }

  }

  .entry-content {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);
    margin-bottom: 10px;

    h2:first-of-type,
    h3:first-of-type {
      margin-top: 0;
    }
  }
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
  .main {
    padding-right: 0;
  }
}

@include breakpoint(md) {
  .main {
    padding-right: 15px;
    margin-bottom: 15px;
  }
  .content {
    padding: 50px 0;
  }

  .page-header {
    padding: 25px 0;
    .page-title {
      margin-bottom: 15px;
      h1 {
        line-height: 1.4 !important;
      }
      span {
        margin-right: 10px;
      }
      img {
        width: 50px;
        padding: 5px;
      }
    }
  }

}

@include breakpoint(sm) {
  .main {
    padding: 0px;
    margin: 0px;
  }
  .page-header {
    padding: 20px 0;
  }
  .content {
    padding: 0px 0px 30px 0;
  }
}
