/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.share-box {
    background-color: #fff;
    padding: 10px 30px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    box-shadow: 0 2px 4px 0 rgba(71,73,96,.09);

    h4 {
        float: left;
        font-weight: bold;
        margin-right: 10px;
    }

    .social {
        text-align: center;
        width: 25px;
        height: 25px;
        border-radius: 30px;
        float: left;
        margin: 9px 3px;
        color: #fff;
        line-height: 28px;

        &.fb {
            background: #3b579d;

            &:hover {
                background: darken(#3b579d, 10);
            }
        }

        &.tw {
            background: #55acee;

            &:hover {
                background: darken(#55acee, 10);
            }
        }

        &.link {
            background: #007bb5;

            &:hover {
                background: darken(#007bb5, 10);
            }
        }

        &.google {
            background: #dd4b39;

            &:hover {
                background: darken(#dd4b39, 10);
            }
        }
    }
}

.content-box {
    .share-box {
        border: 0px;
        padding: 0px;
        box-shadow: none;
        border-bottom: 1px solid $lightgrey;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
}
/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {

}

@include breakpoint(md) {
}

@include breakpoint(sm) {
.share-box { 
    padding: 5px 15px;

    h4 {
        font-size: 12px;
        line-height: 24px;
    }
}

}
