.sticky_message {
  position: fixed;
  top: 50px;
  right: 50px;
  width: 300px;
  display: none;
  z-index: 999999;

  .close {
    float: right;
    color: #000;
    background: #fff;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    text-align: center;
    line-height: 25px;
    margin: 0px -10px -10px 0px;
    z-index: 10;
    position: relative;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;

    &:hover {
      background: #000;
      color: #fff;
    }
  }

  .message {
    width: 100%;
    float: left;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    padding: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
    line-height: 21px;
    color: #fff;
    position: relative;
    padding-left: 55px;
    min-height: 57px;
    text-decoration: none;
    padding-left: 60px;

    &:hover {
      background: rgba(0, 0, 0, 1);
      text-decoration: none !important;
    }


    .fa {
      position: absolute;
      left: 15px;
      top: 18px;
      font-size: 26px;
    }
  }
}


@include breakpoint(md) {
  .sticky_message {
    top: auto;
    bottom: 15px;
    width: calc(100% - 30px);
    left: 15px;
    z-index: 99999999999;
  }
}