.discount_modal,
.discount_modal_onload {
  background: rgba(0, 0, 0, 0.8);
  display: none;
  float: left;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 10000;
  position: fixed;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;

  #countdown {
    left: 50%;
    float: left;
    width: 100%;
    text-align: center;
  }

  .box {
    width: 600px;
    float: left;
    left: 50%;
    margin: 100px 0px 100px -300px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    position: relative;
    .fa-times {
      position: absolute;
      right: -30px;
      font-size: 30px;
      color: #fff;
      top: 0px;
      cursor: pointer;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
      &:hover {
        opacity: 0.8;
      }
    }
    .discount {
      font-weight: bold;
      width: 100%;
      text-align: center;
      font-size: 90px;
      line-height: 90px;
      margin-top: 50px;
      margin-bottom: 0px;
      color: #fff;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
    }
    .discount_rule {
      width: 100%;
      float: left;
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      color: #fff;
      margin: -5px 0px 10px 0px;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
    }
    .discount_sub {
      width: 100%;
      float: left;
      font-weight: 400;
      font-size: 21px;
      text-align: center;
      color: #fff;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
    }
    .price-box {
      width: 80%;
      float: left;
      background: #fff;
      padding: 10px;
      border-radius: 15px;
      margin: 10px 0px 50px 0px;
      text-align: center;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
      .pack {
        font-size: 18px;
        font-weight: bold;
        width: 100%;
        float: left;
      }
      .month {
        font-size: 12px;
        width: 100%;
        float: left;
        line-height: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
        .new_year {
          font-weight: bold;
        }
        .old_year {
          color: $green;
          text-decoration: line-through;
        }
      }
      .type {
        width: 100%;
        float: left;
        font-size: 16px;
        opacity: 0.8;
        border-bottom: 1px solid #eee;
        padding-bottom: 5px;
        margin-bottom: 10px;
      }
      .num {
        float: left;
        width: 60%;
        font-size: 32px;
        font-weight: bold;
        text-align: right;
        padding-right: 5px;
        box-sizing: border-box;
      }
      .btn {
        width: 100%;
        padding: 10px 0px;
        border-radius: 5px;
        font-size: 26px;
        font-weight: bold;
        color: #fff;
        background: $green;
        float: left;
        border-bottom: 2px solid darken($green, 10);
        &:hover {
          background: darken($green, 10);
          text-decoration: none;
        }
      }
      .extra {
        font-size: 14px;
        width: 100%;
        float: left;
        margin: 10px 0px;
      }
      .per {
        float: left;
        width: 40%;
        line-height: 13px;
        float: left;
        font-size: 13px;
        opacity: 0.8;
        text-align: left;
      }
    }
    .bottom {
      width: 100%;
      float: left;
      padding: 15px;
      .logo {
        float: left;
        img {
          width: 150px;
          height: auto;
        }
      }
      .quote {
        float: right;
        font-size: 24px;
        opacity: 0.8;
        margin-top: 5px;
      }
    }
    h3 {
      color: #fff;
      width: 100%;
      float: left;
      text-align: center;
      margin: 20px 0px;
      font-size: 36px;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
      span {
        font-weight: bold;
      }
    }
    .inner {
      background-color: #c4c4c4;
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      float: left;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

@include breakpoint(sm) {
  .discount_modal {
    .box {
      width: 96%;
      margin: 50px 0px 50px 2%;
      left: 0px;
      h3 {
        font-size: 26px;
        padding: 0px 20px;
      }
      .discount {
        font-size: 50px;
        line-height: 50px;
        float: left;
        margin-top: 0px;
      }
      .discount_sub {
        font-size: 18px;
        line-height: 26px;
      }
      .fa-times {
        right: 0px;
        top: -30px;
      }
      .price-box {
        width: 96%;
        margin-left: 2%;
        margin-bottom: 20px;
      }
      .bottom {
        .logo {
          left: 50%;
          position: relative;
          margin-left: -75px;
        }
        .quote {
          display: none;
        }
      }
    }
  }
}