html {
  background: #f9f7f7;
}

body {
  height: 100%;
  //padding-top: 66px;
  font-family: $fontFamily;
  font-size: $fontSize;
  line-height: $lineHeight;
  color: $text;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  letter-spacing: 0.4px;
}

.wrapper {
  background-color: $bg-color;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
}

h4.coupon {
  padding: 15px;
  display: block;
  text-align: center;
}

a {
  color: $link;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
  transition: color 0.2s linear;
  &:hover {
    color: $link-dark;
  }
  &:active {
    color: $link-dark;
  }
}

.alert.alert-warning {
  text-align: center;
  padding:10px;
  border-bottom: 1px solid $lightgrey;
}

a.read_more {
  color: $brand-primary;
  font-weight: $f-bold;
  text-decoration: none;
  transition: all 0.5s ease;
}

a.read_more:hover {
  //margin-left: 10px;
}

input,
textarea {
  border-radius: 0;
}

input,
textarea {
  -webkit-appearance: none;
}

hr {
  border-color: $lightgrey;
}

.load-overlay {
  width: 100%;
  height: 100%;
  background-color: $white;
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
}

.breadcrumb {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  font-size: 14px;
  border-radius: 0;
  //background-color: $brand-primary;
  color: rgba(0, 0, 0, 0.8);
  #breadcrumbs {
    margin: 0 0 0 -10px;
  }
  a {
    color: $footer-link;
    margin: 0 10px;
  }
  .breadcrumb_last {
    margin-left: 10px;
  }
}

.pagination {
  text-align: center;
  span,
  a {
    background-color: $link;
    padding: 10px;
    color: $white;
    transition: all 0.2s linear;
    text-decoration: none;
    border: 1px solid $link;
  }
  span {
    background-color: $white;
    color: $text;
    border-color: $lightgrey;
  }
  a {
    &:hover,
    &:active {
      background-color: $white;
      color: $text;
      border-color: $lightgrey;
    }
  }
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

.sitemap {
  ul {
    list-style-type: none;
  }
}

.tags {
  margin-top: 10px;
  a {
    background: $brand-secondary;
    height: 24px;
    display: inline-block;
    line-height: 24px;
    color: #fff;
    font-size: 11px;
    text-decoration: none;
    font-weight: 700;
    border-radius: 3px;
    padding: 0 16px;
    margin-right: 8px;
    text-transform: uppercase;
    margin-bottom: 8px;
    transition: background-color .2s ease;

    &:hover, &:focus {
      background-color: $link;
    }

  }

}

.size-full {
  max-width: 100%;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
}

@include breakpoint(md) {
  h1, .h1 {
    font-size: $font-size-h1 - 4px;
  }
  h2, .h2 {
    font-size: $font-size-h2 - 3px;
  }
  h3, .h3 {
    font-size: $font-size-h3 - 2px;
  }
  h4, .h4 {
    font-size: $font-size-h4 - 1px;
  }
  h5, .h5 {
    font-size: $font-size-h5 - 1px;
  }
  h6, .h6 {
    font-size: $font-size-h6 - 1px;
  }
  .pagination {
    padding: 6px 0 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #DDD;
  }
  .breadcrumb {
    white-space: nowrap;
    display: block;
    overflow-x: auto;
  }
}

@include breakpoint(sm) {
  h1, .h1 {
    font-size: $font-size-h1 - 5px;
  }
  h2, .h2 {
    font-size: $font-size-h2 - 4px;
  }
  h3, .h3 {
    font-size: $font-size-h3 - 3px;
  }
  h4, .h4 {
    font-size: $font-size-h4 - 3px;
  }
  h5, .h5 {
    font-size: $font-size-h5 - 1px;
  }
  h6, .h6 {
    font-size: $font-size-h6 - 1px;
  }
  .breadcrumb {
    padding: 5px 0;
    margin-top: 0;
    #breadcrumbs {
      margin: 0;
    }
    a {
      margin: 0;
    }
    .breadcrumb_last {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}
