/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.best_cats {
    width: 100%;
    float: left;
    background: #fff;
    border: 1px solid $lightgrey;
    margin-bottom: 40px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);

    .cat_row {
        width: 100%;
        padding: 25px 100px 5px 100px;
        float: left;
        box-sizing: border-box;

        &.odd {
            background: $bg-color;
            box-shadow: inset 0px -4px 5px 0px rgba(0,0,0,0.05);
        }

        .item {
            width: 20%;
            float: left;
            margin-top: 10px;
            text-align: center;
            margin-bottom: 20px;
            text-decoration: none !important;

            &:hover {
                opacity: 0.5;
            }

            h3 {
                font-size: 16px;
                color: $text;
            }

            img {
                width: auto;
                height: 45px;
            }
        }
    }
}

.best-cat_title {
    font-weight: bold;
    font-size: 26px;
    margin: 0px;
    text-align: center;
    padding: 20px 0px;
    border-bottom: 1px solid $lightgrey;
}
/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
    .best_cats {
        .cat_row {
            padding: 25px 50px;
        }
    }
}

@include breakpoint(md) {
    .best_cats {
        .cat_row {
            padding: 25px 0px;
        }
    }
}

@include breakpoint(sm) {
    .best-cat_title {
        font-size: 18px;
    }
    .best_cats {
        .cat_row {
            .item {
                width: 50%;
                margin-bottom: 20px;
            }
        }
    }
}
