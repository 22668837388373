.providers {

  .search {
    background-color: $white;
    margin-bottom: 40px;
    box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);

    label {
      margin-bottom: 0;
      width: 80%;
      //border: 1px solid #ddd;
      float: left;
      border-right: 0;
    }

    .search-field {
      width: 100%;
      font-weight: 500;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      border-color: transparent;
      background-color: #fff;
      padding: 8px 15px;
      color: #050505;
      height: auto;
      font-size: 15px;
      box-shadow: inset 0 -15px 17px -10px rgba(0, 0, 0, .02);
      transition: background-color .2s ease;
  }

    .search-submit {
      transition: background-color .2s ease, border-color .2s ease;
      width: 20%;
      float: right;
      padding: 10px;
      border: 0;
      border: 1px solid #162b32;
      background-color: #162b32;
      color: #fff;
    }

  }

  .filter {
    background-color: $white;
    padding: 10px 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);
    //border: 1px solid #ddd;

    &_header {
      display: inline-block;
    }

    &-trigger {
      float: right;
      display: none;
    }

    ul {
      list-style-type: none;
      float: right;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        padding-left: 10px;

        a {

          &:hover, &:focus {
            text-decoration: none;
          }

          .order {
            .fa {
              color: $lightgrey;

              &.disabled {
                color: $gray;
              }

            }

          }

        }

        &.active {
          a {
            font-weight: bold;
            color: $brand-primary;
          }
        }

      }

    }

  }

  .provider {
    text-align: center;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(71,73,96,.09);
    margin-bottom: 30px;
    position: relative;

    .score, .waardering.last {
      font-size: 13px;
      line-height: 14px;
    }

    .waardering-total {
      font-size: 11px;
      line-height: 11px;
    }

    .position {
      position: absolute;
      width: 35px;
      height: 35px;
      top: -17.5px;
      left: -17.5px;
      line-height: 35px;
      text-align: center;
      background-color: $bg-color;
      color: $brand-primary;
      font-weight: bold;

      &.top {
        background-color: $green;
        color: $white;
      }
    }

    .provider_logo {
      display: block;
      min-height: 90px;
      position: relative;

      .provider_label {
        font-size: 12px;
      }

      img {
        max-height: 100%;
        max-width: 100%;
        width: 75%;
        max-width: 200px;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .meta {
      color: $text-color;
      display: block;
      padding: 10px;

      .provider_name {
        display: block;
        transition: all .2s ease;
      }

    }

    a {
      &:hover,
      &:focus {
        text-decoration: none;
        color: $text-color;

        .provider_name {
          color: $link;
        }

      }
    }

    .filter-content {
      font-size: 13px;
      line-height: 1.5;
      padding: 8px 0;
      margin: 10px;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;

      strong {
        display: block;
      }

      .fa {
        color: $green;
      }

    }

  }

}

.main {
  padding-right: 15px;
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
}

@include breakpoint(md) {
  .providers {

    .filter {
      padding: 0;

      &_header {
        cursor: pointer;
        padding: 10px 15px;
        width: 100%;
      }

      ul {
        display: none;
        width: 100%;
        padding: 0 15px 10px;

        li {
          padding-left: 0;
          display: block;
        }

      }

      &-trigger {
        display: inline-block;

        i {
          transition: all .2s ease;
        }

        &.open {
          i {
            transform: rotate(180deg);
          }
        }

      }

    }

  }

}

@include breakpoint(sm) {
  .providers {

    .provider {

      .position {
        top: -11px;
        left: -11px;
      }

      .provider_logo {
        height: 100px;
        max-height: 100px;

        img {
          width: 70%;
        }

      }

      a.meta {
        .provider_name {
          font-weight: bold;
        }
      }

    }

    .search {
      margin-bottom: 25px;

      label {
        width: 70%;
      }

      .search-submit {
        width: 30%;
      }

    }

  }

}
