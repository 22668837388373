.review-wrapper {
  margin-top: 15px;
  background-color: $white;
  padding: 20px 15px;
  box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);

  .review-cta {
    width: 100%;
    border-top: 1px solid $lightgrey;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $lightgrey;

    img {
      width:100%;
      height:auto;
    }

  }

  .review-summary-wrapper {
    border-bottom: 1px solid $lightgrey;
    padding-bottom: 15px;

    h3 {
      margin-top: 0;
      font-weight: bold;

      &.small {
        font-weight: 300;
        font-size: 18px;
      }
    }

    .average-rating {

      i {
        color: #ffb400;
        font-size: 30px;
      }

    }

  }

}

.comments-area {
  h3 {
    font-weight: bold;
  }
  
  &.reviews {

    .says {
      display: none;
    }

    #reply-title {
      display: none;
    }

    .pixrating_title {
      margin-top: 10px;
    }

    .review_rate,
    #add_comment_rating_wrap {
      i {
        color: #ffa800;
        font-size: 20px;
      }
    }

    #add_comment_rating_wrap {
      width: 205px;
      i {
        font-size: 26px;
      }
    }

    .review-title-form {
      margin-top: 15px;
    }

    label {
      font-size: 18px;
      display: block;
    }

    .comment-list-limiter {
      height: 150vh;
      overflow: hidden;
      position: relative
    }

    .comment-list-limiter-gradient {
      position: absolute;
      bottom: 0;
      min-height: 500px;
      display: block;
      width: 100%;
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0) 26%, rgba(255, 255, 255, .75) 60%, rgba(255, 255, 255, 1) 100%);
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0) 26%, rgba(255, 255, 255, .75) 60%, rgba(255, 255, 255, 1) 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0) 26%, rgba(255, 255, 255, .75) 60%, rgba(255, 255, 255, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0)
    }

    .comment-list-showmore {
      font: 500 17px Droid Sans, Helvetica, Arial, sans-serif;
      padding: 10px 20px;
    }
    .comment-respond {
      margin-top: 0;
    }

    .comment-author,
    .comment-metadata {
      display: inline-block;
    }

    .comment-metadata {
      float: right;
    }

    .comment-form {
      margin: 0;
      padding: 0;
      border: 0;
    }
    
    .comment-subscription-form {
      display: none;
    }

  }

}

@include breakpoint(sm) {
  .comments-area.reviews .comment-metadata {
    float: none;
    display: block;
  }
}