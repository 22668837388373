.error {
    .content {
        text-align: center;
        padding: 100px 0;
        i {
            font-size: 200px;
            opacity: 0.1;
        }
        form {
            width: 600px;
            margin: 40px auto 0;
            .screen-reader-text {
                display: none;
            }
            label {
                margin-bottom: 0;
                width: 70%;
                border: 1px solid $lightgrey;
                float: left;
                border-right: 0;
            }
            .search-field {
                width: 100%;
                border-radius: 0;
                outline: none;
                box-shadow: none;
                border-color: transparent;
                background-color: $white;
                padding: 10px;
                color: darken($text, 10%);
                height: auto;
                font-size: 15px;
                box-shadow: inset 0 -15px 17px -10px rgba(0, 0, 0, .02);
                transition: background-color .2s ease;
            }
            .search-submit {
                transition: background-color .2s ease, border-color .2s ease;
                width: 30%;
                float: right;
                padding: 12px;
                border: 0;
                border: 1px solid $brand-primary;
                background-color: $brand-primary;
                color: $white;
                &:hover,
                &:focus {
                    background-color: $link;
                    border: 1px solid $link;
                }
            }
        }
    }
}


/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {}

@include breakpoint(sm) {}
