.related_partner {
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 100000;
    display: none;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;

    .close {
        position: absolute;
        right: 15px;
        top: 15px;
        color: #000;
        font-size: 21px;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    .inside {
        width: 500px;
        left: 50%;
        position: relative;
        margin: 50px 0px 50px -250px;
        float: left;
        border-radius: 3px;
        background: #fff;
        padding: 40px;
        box-sizing: border-box;
        text-align: center;

        img {
            width: 100%;
            float: left;
        }
        
        h3 {
            color: #000;
            margin: 0px;
            line-height: 32px;
            font-weight: bold;
            font-size: 28px;

            span {
                color: #f11261;
            }
        }

        .logo {
            max-width: 270px;
            margin: 30px 0px 0px 0px;
        }

        .review_mobile {
            color: rgba(0, 0, 0, 0.7);
        }

        .button_popup {
            width: 100%;
            margin-top: 25px;
            float: left;
        }

        .voordelen {
            width: 100%;
            float: left;
            ul {
                list-style: none;
                width: 100%;
                padding: 10px 0px;
                float: left;
            }
            .fa {
                color: #29a954;
            }
            a {
                color: #000;
            }
        }

        .logo_wrap {
            margin-top: 20px;
            width: 100%;
            float: left;
            position: relative;

            .leden {
                position: absolute;
                right: 0px;
                top: 0px;           
              text-align: center;
              float: left;
              border-radius: 10px;
              color: #29a954;
              font-size: 14px;
              font-weight: 700;
              transition: all 1.5s ease;
              z-index: 1;

              &.show {
                margin-bottom: 15px;
                margin-top: -5px;
                opacity: 1;
              }

              .fa {
                position: absolute;
                left: 50%;
                font-size: 60px;
                margin-left: -12px;
                top: -43px;
                text-shadow: 0 4px 5px rgba(57,151,18,.2);
              }

              .round{
                width: 15px;
                height: 15px;
                border-radius: 20px;
                background: #29a954;
                display: inline-block;
                position: relative;
                top:2px;
                right: 5px;
                box-shadow: 0 0 0 0 rgba(57,151,18,1);
                transform: scale(1);
                animation: pulse 2s infinite;
              }

              @keyframes pulse {
                0% {
                  transform: scale(0.95);
                  box-shadow: 0 0 0 0 rgba(57,151,18, 0.7);
                }

                70% {
                  transform: scale(1);
                  box-shadow: 0 0 0 10px rgba(57,151,18, 0);
                }

                100% {
                  transform: scale(0.95);
                  box-shadow: 0 0 0 0 rgba(57,151,18, 0);
                }
              }
            }
        }
    }
}
/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {}

@include breakpoint(sm) {
    .related_partner {
        z-index: 99999999;

        .inside {
            width: 95%;
            margin: 20px 2.5%;
            min-height: auto;
            left: 0px;
            padding: 30px;

            h3 {
                font-size: 24px;
            }
        }
    }
}
