.form-control-wrap {
  display: block;
  margin-bottom: 15px;

  label {
    display: block;
    //font-weight: bold;
    margin-bottom: 5px;
  }

}

.form-control {
  width: 100%;
  font-weight: 500;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  border-color: transparent;
  background-color: $white;
  padding: 15px;
  color: #050505;
  height: auto;
  font-size: 17px;
  line-height: 1.2em;
  box-shadow: 0 2px 4px 0 rgba(71,73,96,.09);
  transition: background-color .2s ease;
}



.mailchimp {
  .input {
    border: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    border-color: transparent;
    background-color: $grey;
    color: darken($text, 10%);
    height: auto;
    padding: 8px 15px;
    font-size: 15px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: inset 0 -15px 17px -10px rgba(0, 0, 0, .02);
    transition: background-color .2s ease;
    border: 2px solid #e5e5e5;
    &:hover,
    &:focus {
      outline: none;
    }
  }
  .button {
    font: $f-medium 17px $fontCaption !important;
    padding: 10px 20px;
    &:hover,
    &:focus {
      outline: none;
    }
  }
}

.mc4wp-response {
  display: inline-block;
  margin-top: 10px;
}

footer {
  .mc4wp-response {
    color: #fff;
  }
  .mailchimp {
    .input {
      background-color: #fff;
      padding: 10px;
      border: transparent;
    }
  }
}

input[type="radio"] {
  border: 1px solid #000;
  width: 30px;
  height: 30px;
}

input[type="radio"]:checked {
  background-color: #000;
}

input,
textarea {

  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #9a9898;
  }

  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #9a9898;
    opacity: 1;
  }

  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #9a9898;
    opacity: 1;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #9a9898;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #9a9898;
  }
}

@include breakpoint(sm) {

  .form-control {

    padding: 10px;
    font-size: 15px;

  }

  .form-control-wrap {

    label {
      margin-bottom: 2px;
    }

  }

}