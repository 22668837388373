.comments-area {

  .comments-title {
    margin-top: 40px;
  }

  .comment-list {
    list-style: none;
    padding-left: 0;

    ol {
      list-style-type: none;
    }

    footer {
      background-color: transparent;
      padding: 0;
    }

    .comment-author.vcard img {
      display: none;
    }

    .comment-body {
      border-bottom: 1px solid $lightgrey;
      padding: 15px 0;
    }

    .comment-awaiting-moderation {
      color: red;
      margin-top: 10px;
    }

  }
  .comment-form {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $lightgrey;

    .comment-form-url {
      display: none;
    }

  }

  .comment-form input[type="text"],
  .comment-form input[type="email"],
  .comment-form input[type="url"],
  .comment-form textarea {
    border: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    border-color: transparent;
    background-color: $grey;
    color: darken($text, 10%);
    height: auto;
    padding: 8px 15px;
    font-size: 15px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: inset 0 -15px 17px -10px rgba(0, 0, 0, .02);
    transition: background-color .2s ease;
    border: 2px solid #e5e5e5;
    &:hover,
    &:focus {
      outline: none;
    }
  }

  .comment-form textarea {
    height: 170px;
    margin-bottom: 0;
  }

  comment-form-comment {
    margin-bottom: 0;
  }

  .comment-form-author,
  .comment-form-email {
    width: 50%;
    float: left;
  }

  .comment-form-author {
    padding-right: 7.5px;
  }

  .comment-form-email {
    padding-left: 7.5px;
  }

  .comment-form input[type="submit"] {
    color: $white;
    background: $green;
    border: 2px solid $green;
    font: $f-medium 17px $fontCaption;
    padding: 10px 20px;
    transition: color 0.1s linear, background-color 0.1s linear, border-color 0.1s linear;
    border-radius: 5px;
    box-sizing: border-box;
    &:hover,
    &:active {
      background: darken($green, 5%);
      border-color: darken($green, 5%);
    }
    .fa {
      margin-left: 10px;
    }
  }

  &.review {
    border-top: 1px solid #ddd;

    .comment-form-email {
      width: 100%;
      padding-left: 0;
    }

    #cancel-comment-reply-link {
      display: none !important;
    }

    #add_comment_rating_wrap {
      width: 100%;
      margin-bottom: 10px;

      label {
        display: block;
        font-size: 16px;
      }

      i {
        color: #ffb400;
      }

      #add_post_rating {
        bottom: 4px;
        left: 140px;
      }

      .review-title-form {
        display: none;
      }

    }

  }

}