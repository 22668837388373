.button {
    color: $white;
    /*background: rgb(233,6,211);
    background: -moz-linear-gradient(90deg, rgba(233,6,211,1) 0%, rgba(255,0,106,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(233,6,211,1) 0%, rgba(255,0,106,1) 100%);
    background: linear-gradient(90deg, rgba(233,6,211,1) 0%, rgba(255,0,106,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e906d3",endColorstr="#ff006a",GradientType=1); */
    background: rgb(93,185,24) !important;
    background: -moz-linear-gradient(180deg, rgba(93,185,24,1) 0%, rgba(25,143,16,1) 100%) !important;
    background: -webkit-linear-gradient(180deg, rgba(93,185,24,1) 0%, rgba(25,143,16,1) 100%) !important;
    background: linear-gradient(180deg, rgba(93,185,24,1) 0%, rgba(25,143,16,1) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5db918",endColorstr="#198f10",GradientType=1) !important;
    border: 0px;
    font: $f-medium 22px $fontCaption;
    padding: 0 21px;
    //transition: color 0.1s linear, background-color 0.1s linear, border-color 0.1s linear;
    border-radius: 5px;
    box-sizing: border-box;
    font-weight: bold;
    &:hover,
    &:active {
     background: rgb(93,185,24) !important;
    }
    .fa {
        margin-left: 10px;
    }
}

a.button {
    text-decoration: none;
    display: inline-block;
    line-height: 1.2;
    padding: 15px 20px;
    color: $white;
    &:hover {
        color: $white;
    }
    &:active {
        color: $white;
    }
    &.button-stripe {
        background-color: rgba(0, 0, 0, 0.2);
        border-color: $white;
        &:hover {
            color: $text;
            background-color: $white;
            border-color: $white;
        }
        &:active {
            color: $text;
            background-color: $lightgrey;
            border-color: $lightgrey;
        }
    }
}


/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
    .button {
        font-size: 17px;
    }
}

@include breakpoint(md) {}

@include breakpoint(sm) {}
