/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.sidebar {
    .widget {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            &:hover,
            &:focus {
                text-decoration: none;
                .widget-titel {
                    //background-color: lighten($brand-primary, 10%);
                }
            }
        }
        .widget-titel {
            text-align: center;
            //background-color: $brand-primary;
            //color: $white;
            background: #fff;
            margin-bottom: 1px;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
            //transition: color 0.1s linear, background-color 0.1s linear, border-color 0.1s linear;
            h3 {
                height: auto;
                margin: 0;
                font-weight: bold;
                line-height: 40px;
                text-align: center;
                font-size: 18px;
            }
        }
        .widget-content {
            background-color: $white;
            padding: 15px;
            //border: 1px solid $lightgrey;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
        }
        &.top-list,
        &.toplist_compare_choice {
            .widget-content {
                background-color: transparent;
                padding: 0;
                border: 0;
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                .item {
                    border-bottom: 1px solid $lightgrey;
                    padding: 15px;
                    background: $white;
                    line-height: 100%;
                    .left-column {
                        float: left;
                        width: 45%;
                        a {
                            display: block;
                        }
                        .logo {
                            img {
                                width: auto;
                                height: auto;
                                max-width: 90%;
                            }
                        }
                        .rating {
                            margin-top: 5px;
                        }
                        .discount {
                            font-weight: bold;
                            margin-top: 10px;
                            color: #36a0c7;
                            font-size: 90%;
                        }
                    } 
                    .right-column {
                        float: right;
                        width: 55%;
                        .button {
                            text-align: center;
                            display: block;
                            font-size: 16px;
                            line-height: 1.4;
                            height: auto;
                            padding: 10px 20px;
                            float: right;
                            margin-bottom: 10px;
                        } 
                        
                        a {
                            display: block;
                        }
                    }
                    &.first {
                        //border-top: 1px solid $lightgrey;
                    }
                }
                .archive_link {
                    //border: 1px solid $lightgrey;
                    border-top: 0;
                    padding: 10px 15px;
                    background: $white;
                    line-height: 100%;
                    text-align: center;
                }
            }
        }
        &.related {
            .widget-content {
                background-color: transparent;
                padding: 0;
                border: 0;
            }
            .item {
                background-color: $white;
                border: 1px solid $lightgrey;
                margin-bottom: 5px;
                padding: 0 15px 15px 15px;
                header {
                    margin: 0 -15px;
                }
            }
        }
        &.widget_search {
            .widget-content {
                padding: 0;
                border: 0;
                overflow: auto;
                zoom: 1;
            }
            .screen-reader-text {
                display: none;
            }
            label {
                margin-bottom: 0;
                width: 70%;
                //border: 1px solid $lightgrey;
                float: left;
                border-right: 0;
            }
            .search-field {
                width: 100%;
                border-radius: 0;
                outline: none;
                box-shadow: none;
                border-color: transparent;
                background-color: $white;
                padding: 10px;
                color: darken($text, 10%);
                height: auto;
                font-size: 15px;
                box-shadow: inset 0 -15px 17px -10px rgba(0, 0, 0, .02);
                transition: background-color .2s ease;
            }
            .search-submit {
                transition: background-color .2s ease, border-color .2s ease;
                width: 30%;
                float: right;
                padding: 12px;
                border: 0;
                border: 1px solid $brand-primary;
                background-color: $brand-primary;
                color: $white;
                &:hover,
                &:focus {
                    background-color: $link;
                    border: 1px solid $link;
                }
            }
        }
        &.widget_nav_menu,
        &.widget_pages,
        &.widget_categories {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;
                li {
                    a {
                        color: $link_dark;
                        transition: color .2s ease;
                        &:hover,
                        &:focus {
                            color: $link;
                        }
                    }
                }
            }
        }
    }
}

.aside.is_stuck {
    .sidebar {
        padding-top: 180px;
        .spacer {
            height: 200px;
            width: 100%;
            float: left;
            display: block;
        }
    }
}

.aside {
    .sidebar {
        .spacer {
            height: 200px;
            width: 100%;
            float: left;
            display: block;
        }
    }
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
    .sidebar {
        .widget {
            margin-bottom: 15px;
            &.top-list,
            &.toplist_compare_choice {
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    .item {
                        border: 1px solid $lightgrey;
                        border-top: 0;
                        padding: 15px;
                        background: $white;
                        line-height: 100%;
                        .left-column {
                            float: left;
                            .logo {
                                img {
                                    width: auto;
                                    height: 25px;
                                    max-width: 165px;
                                }
                            }
                            .rating {
                                margin-top: 5px;
                            }
                        }
                        .right-column {
                            float: right;
                            .button {
                                font-size: 16px;
                                line-height: 1.4;
                                height: auto;
                                padding: 10px;
                            }
                        }
                        &.first {
                            border-top: 1px solid $lightgrey;
                        }
                    }
                    .archive_link {
                        border: 1px solid $lightgrey;
                        border-top: 0;
                        padding: 10px 15px;
                        background: $white;
                        line-height: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@include breakpoint(md) {
    .sidebar {
        .widget {
            margin-bottom: 15px;
            &.top-list,
            &.toplist_compare_choice {
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    .item {
                        border: 1px solid $lightgrey;
                        border-top: 0;
                        padding: 15px;
                        background: $white;
                        line-height: 100%;
                        .left-column {
                            width: auto;
                            float: left;
                            .logo {
                                img {
                                    width: auto;
                                    height: 25px;
                                    max-width: 165px;
                                }
                            }
                            .rating {
                                margin-top: 5px;
                            }
                        }
                        .right-column {
                            width: 30%;
                            float: right;
                            wid
                            .button {
                                font-size: 16px;
                                line-height: 1.4;
                                height: auto;
                                padding: 10px;
                            }
                        }
                        &.first {
                            border-top: 1px solid $lightgrey;
                        }
                    }
                    .archive_link {
                        border: 1px solid $lightgrey;
                        border-top: 0;
                        padding: 10px 15px;
                        background: $white;
                        line-height: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }

    .aside {

        .sidebar {
            padding-top: 20px;
        }

        &.is_stuck {
            position: relative !important;
            top: 0px !important;
            width: 100% !important;
            padding-top: 0px;

            .sidebar {
                padding-top: 20px;
            }

            .spacer {
                display: none !important;
            }
        }

    }
    
    .spacer_block {
        display: none !important;
    }

}

@include breakpoint(sm) {
    .sidebar {
        .widget {
            margin-bottom: 15px;
            &.top-list,
            &.toplist_compare_choice {
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    .item {
                        border: 1px solid $lightgrey;
                        border-top: 0;
                        padding: 15px;
                        background: $white;
                        line-height: 100%;
                        .left-column {
                            float: left;
                            text-align: center;
                            width: 100%;
                            .logo {
                                img {
                                    width: auto;
                                    height: 25px;
                                    max-width: 165px;
                                }
                            }
                            .rating {
                                margin-top: 5px;
                            }
                        }
                        .right-column {
                            float: right;
                            width: 100%;
                            text-align: center;
                            .button {
                                font-size: 16px;
                                line-height: 1.4;
                                height: auto;
                                padding: 10px;
                                display: inline-block;
                                margin: 10px 0px;
                            }
                            a {

                                small {
                                    width: 100%;
                                    text-align: center;
                                    margin-top: 0 !important;
                                    margin-right: 0 !important;
                                }

                            }
                        }
                        &.first {
                            border-top: 1px solid $lightgrey;
                        }
                    }
                    .archive_link {
                        border: 1px solid $lightgrey;
                        border-top: 0;
                        padding: 10px 15px;
                        background: $white;
                        line-height: 100%;
                        text-align: center;
                    }
                }
            }
            &.widget_search {
                .search-field {
                    padding: 5px;
                }
                .search-submit {
                    padding: 7px; 
                }
            }
        }
    }
}
