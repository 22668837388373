.iframe-block {

  // Standaard style
  background-color: $white;
  text-align: center;
  transition: all .2s ease;

  a {
    display: block;
    color: $link-dark;
    text-decoration: none;
    transition: all .2s ease;

    position: absolute;
    width: 100%;
    height: 100%;

    &:hover {
      text-decoration: none;
      color: $link-dark;
      background-color: darken($white, 2);
    }

  }

  .brand {
    text-transform: uppercase;
    font-weight: 400;

    img {
      margin-top: -5px;
      height: 35px;
    }

    span {
      font-weight: 600;
    }

  }

  .rating {
    i {

      &.star {
        background-image: url("../images/star.png");
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        height: 30px;
        line-height: 30px;

        &.x2 {
          width: 33px;
          height: 33px;
          line-height: 33px;
        }

        &.x3 {
          width: 38px;
          height: 38px;
          line-height: 38px;
        }

        &.star-o {
          background-image: url("../images/star-o-light.png");
        }

        &.star-half-o {
          background-image: url("../images/star-half-o-light.png");
        }

      }

    }

  }

  .score {

    p {
      margin: 0;
      padding: 0;
    }

    span {
      font-weight: 600;
    }
  }

  // Dark theme
  &.dark {

    a {
      transition: all .2s ease;
      background-color: $brand-primary;
      color: $white;

      &:hover {
        color: $white;
        background-color: lighten($brand-primary, 5);
      }

    }

    .rating {

      i {

        &.star {

          &.star-o {
            background-image: url("../images/star-o.png");
          }

          &.star-half-o {
            background-image: url("../images/star-half-o.png");
          }

        }

      }

    }

  }

  // small
  &.small {
    //width: 600px;
    //height: 50px;

    .brand {
      display: inline-block;
      vertical-align: middle;
      line-height: 1em;
      font-size: 17px;

      img {
        margin-top: -5px;
        height: 35px;
      }

    }

    .rating {
      display: inline-block;
      vertical-align: middle;
      margin: 6px 10px;
    }

    .score {
      display: inline-block;
      vertical-align: middle;

      p {
        line-height: 1em;
        font-size: 15px;
      }

      span {
        font-size: 25px;
        line-height: 1em;
        vertical-align: middle;
      }
    }

  }

  // Medium
  &.medium {
    //width: 300px;
    //height: 115px;

    a {
      padding: 9px 0;
    }

    .brand {
      display: inline-block;
      vertical-align: middle;
      line-height: 1em;
      font-size: 17px;

      img {
        margin-top: -5px;
        height: 32px;
      }

    }

    .rating {
      display: block;
      vertical-align: middle;
      margin: 3px 0;
    }

    .score {
      display: inline-block;
      vertical-align: middle;

      p {
        line-height: 1em;
        font-size: 15px;
      }

      span {
        font-size: 23px;
        line-height: 0.7em;
        vertical-align: middle;
      }
    }

  }

  // Large
  &.large {
    //width: 250px;
    //height: 200px;

    a {
      padding: 23px 0;
    }

    .brand {
      display: inline-block;
      vertical-align: middle;
      line-height: 2em;
      font-size: 19px;

      img {
        margin-top: -5px;
        height: 35px;
      }

    }

    .rating {
      margin: 10px 0 13px;
    }

    .score {
      display: inline-block;

      p {
        line-height: 1em;
        font-size: 16px;
      }

      span {
        margin-top: 12px;
        font-size: 28px;
        line-height: 1em;
        display: block;
      }
    }

  }

}

@media screen and (max-width: 599px) {
  .iframe-block {
    &.small {

      a {
        padding: 27px 0;
      }

      .brand {
        display: inline-block;
        vertical-align: middle;
        line-height: 1em;
        font-size: 17px;

        img {
          margin-top: -5px;
          height: 32px;
        }

      }

      .rating {
        display: block;
        vertical-align: middle;
        margin: 3px 0;
      }

      .score {
        display: inline-block;
        vertical-align: middle;

        p {
          line-height: 1em;
          font-size: 15px;
        }

        span {
          font-size: 23px;
          line-height: 0.7em;
          vertical-align: middle;
        }
      }
    }
  }
}