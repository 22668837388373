.post-type-archive-aanbieder {

  .providers {

    .provider {

      border-color: #EEEEEE;

      .position {
        background-color: #DEDEDE;

        &.top {
          background-color: $green;
          color: $white;
        }

      }

    }

  }

}