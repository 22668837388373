.home {
  section {
    h3 {
      font-size: 21px;
      line-height: 1em;
      color: #f11261;
      //text-align: center;
      margin-bottom: 10px;
      margin-top: 20;
    }
    h3,
    h4 {
      font-weight: $f-bold;
      width: 100%;
      float: left;
    }

    p {
      width: 100%;
      float: left;
    }

    ul {
      width: 100%;
      float: left;
      li {
        width: 100%;
        float: left;
      }
    }
  }
  .packages {
    padding: 0 0 105px 0;
    margin-top: -200px;
    .package {
      .waardering_total {
        font-size: 12px;
        margin-top: 5px;
        color: gray;
      }
      &.scale {
        transform: scale(1.2);
        z-index: 3;
        backface-visibility: hidden;
      }
      box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);
      background-color: #fff;
      position: relative;
      .package_header {
        display: block;
        height: 105px;
        position: relative;

        &:hover, &:focus {
          .package_logo {

            img {
              opacity: 0.8;
            }

          }
        }

        .package_logo {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin-top: auto;
          margin-bottom: auto;
          padding-left: 0px;
          float: left;
          text-align: center;
          width: 100%;
          height: 40px;

          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }

        }
        .package_sticker {
          display: block;
          float: right;
          position: absolute;
          top: -42px;
          width: 110px;
          right: 5px;
        }
      }
      .package_name {
        display: block;
        background-color: $grey;
        padding: 25px 0;
        text-align: center;
        .price {
          p {
            margin-bottom: 0;
            padding: 0 10px;
            margin-top: 10px;
            line-height: 1.2;
          }
          span {
            color: $link;
            font-weight: $f-bold;
          }
        }
        .aanbieding {
          margin-top: 15px;
          font-size: 13px;
          line-height: 1.9;
          p {
            margin-bottom: 0;
            padding: 0 10px;
          }
          span {
            font-weight: $f-bold;
          }
          del {
            color: $link;
          }

        }
        h3 {
          font-size: 1.500em;
          line-height: 1em;
          margin: 0;
          padding: 0;
          text-align: center;
        }
        h4 {
          font-size: 1.250em;
          margin-top: 12px;
          line-height: 1em;
          margin: 0;
          padding: 0;
          text-align: center;
        }
      }
      .package_description {
        padding: 30px;
        padding-bottom: 25px;
        display: block;
        text-align: center;
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          margin-bottom: 30px;
          width: 80%;
          margin: 0 auto;
        }
        li {
          font-size: 15px;
          line-height: 1.28em;
          margin-bottom: 15px;
          padding-left: 25px;
          text-align: left;
          position: relative;
          &:before {
            content: "\f00c";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            left: 0;
            font-size: 18px;
            color: $green;
            vertical-align: middle;
          }
        }
        .score {
          line-height: 1.3;
          margin-bottom: 30px;
          p {
            margin-bottom: 0;
            font-weight: $f-bold;
          }
        }
        .button {
          display: block;
          margin-top: 30px;
        }
      }
    }
  }
  .providers {
    background-color: $white;
    padding: 60px 0;
    text-align: center;

    .provider {
      border: 3px solid $bg-color;
      box-shadow: none;
    }

    a.read_more {
      display: block;
      max-width: 320px;
      margin: 0 auto;
      font: $f-medium 17px $fontCaption !important;
    }

  }

  .benefits {
    padding: 60px 0 0;
  }

  .related {
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 50px;

    header {
      margin: 0;
    }

    .post-meta {
      font-size: 14px;
      color: #506373;
      padding: 0 15px;

      span i.fa,
      time i.fa {
        position: relative;
        top: -1px;
        font-size: 12px;
        margin-right: 2px;
        color: #8fa2b3;
      }
      time {
        margin-left: 10px;
      }
    }

    .item {
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);

      &-content {
        padding: 10px 15px 10px 15px;

        h4 {
          margin-top: 0;
          font-size: 18px;
          line-height: 1.5;
          margin-bottom: 15px;
        }

        p {
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 0;
        }

      }

      .read_more {
        display: block;
        padding: 0 15px;
      }

      .tags {
        padding: 0 15px 15px;
      }

    }

  }
  .related {
    a.read_more {
      display: block;
      text-align: center;
      max-width: 320px;
      margin: 30px auto 0 auto;
      font: $f-medium 17px $fontCaption !important;
    }
  }
}

.open_text {
  width: 100%;
  color: rgba(0, 0, 0, 0.3);
  padding: 10px 0px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  &.active .fa {
    transform: rotate(90deg);
  }
}

.hidden_text {
  display: none;
}

.intro_text {
  width: 100%;
  float: left;
  text-align: center;
  line-height: 26px;
  padding: 50px 0px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 50px 0px;

  @include breakpoint(md) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    margin-top: 0px;
    border-top: 0px solid #eee;
  }

  img {
    margin-bottom: 20px;
  }

  p {
    padding: 0px 150px;
  }

  .hidden_text {
    text-align: left;

    p {
      padding: 0px;
    }
  }

  .content {
    padding: 0px;
  }

  @include breakpoint(lg) {
    p {
      padding: 0px;
    }
  }
}


.testimonials {
  width: 100%;
  float: left;
  background: #f9f7f7;
  padding: 0px 0px 40px 0px;

  @include breakpoint(md) {
    padding-bottom: 10px;
  }
}
.testimonial-reel {
  margin-bottom: 4rem !important;
  margin-top: 4rem;

  @include breakpoint(md) {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .box {
    position: relative;
    margin-bottom: 30px;

    .image {
      margin: 0 auto -45px;
      text-align: center;

      img {
        border-radius: 130px;
        height: 130px;
        margin: 0 auto;
      }
    }

    .test-component {
      background-color: #fff;
      padding: 1rem 2.5rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      min-height: 200px;
    }

    .test-title {
      font-family: $fontFamily;
      color: $brand-primary;
      text-align: center;
      margin-top: 50px;
      font-weight: bold;
      margin-bottom: 5px;
      h4 {
        margin-bottom: 0px;
        font-weight: bold;
      }
    }

    .test-content {
      text-align: center;
    }
  }

  .slick-slide {
    opacity: 0.3;
    transition: opacity 0.3s;
    padding-left: 1rem;
    padding-right: 1rem;

    &.slick-cloned {
      opacity: 0.3;
      transition: opacity 0.3s;
    }

    &.slick-current.slick-active {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: #000;
    border: 0;
    outline: 0;
    background: 0 0;
}

.slick-dots li button::before {
    font-family: slick;
    font-size: 36px;
    line-height: 20px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: rgb(0, 0, 0);
    -webkit-font-smoothing: antialiased;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #000;
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
  .home {
    .packages {
      .package {
        .package_description {
          li {
            font-size: 11px;
          }
        }
        &.scale {
          transform: scale(1.24);
        }
      }
    }
  }
}

@include breakpoint(md) {
  .home {
    section {
      h3 {
        margin-top: 0;
        margin-bottom: 30px;
      }
    }
    .packages {
      .package {
        &.scale {
          transform: scale(1.29);
          .package_description {
            .button {
              font-size: 15px;
              padding: 12px;
            }
          }
        }
        .package_header {
          .package_sticker {
            right: -10px;
            width: 90px;
          }
          .package_logo {
            padding-left: 20px;
          }
        }
        .package_description {
          padding: 15px;
          ul {
            width: 82%;
          }
          li {
            padding-left: 25px;
            font-size: 11px;
            margin-bottom: 12px;
          }
        }
      }
    }
    .providers {
      padding: 50px 0;
      a.read_more {
        margin-top: 20px;
      }
    }
    .benefits {
      padding: 50px 0 0;
    }
  }
}

@include breakpoint(sm) {
  .home {
    .packages {
      padding: 0;
      .package {
        margin-bottom: 15px;
        &.scale {
          transform: none;

        }
        .package_header {
          text-align: center;
          height: 75px;
          .package_logo {
            position: relative;
            width: 100%;
            padding: 18px;
            height: 75px;
          }
          .package_sticker {
            width: 110px;
          }
        }
        .package_name {
          padding: 20px 0;
        }
        .package_description {
          ul {
            width: 250px;
            li {
              font-size: 15px;
              margin-bottom: 12px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .button {
            margin-top: 20px;
          }
        }
      }
    }
    .providers {
      padding: 30px 0;
      a.read_more {
        font-size: 15px;
        margin-top: 0;
      }
    }
    .benefits {
      padding: 30px 0 0;
    }
    .related {
      padding-bottom: 30px;
      .post-meta {
        .author {
          display: block;
        }
        time {
          margin-left: 0;
        }
      }
    }
  }
}
