.coupon--strip {
    padding: 10px 0;
    background-color: #fff;
    border-bottom: 1px solid #eee;

    .container {
        position: relative;
    }

    .coupon-text {
        display: block;
        width:100%;
        position: relative;
    }

    a {
        color: $text;
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: none;
        }

        &.btn-close {
            position: absolute;
             top: 50%;
            transform: translateY(-50%);
            line-height: 16px;
            margin-top: -1px;
            right: 15px;
            color: #000;
            text-decoration: none;
            cursor: pointer;
            filter: alpha(opacity=30);
            opacity: .3;
        }
    } 

    .button {
        position: absolute;
        padding: 5px 15px;
        font-size: 18px;
        top: 50%;
        right: 0px;
        font-weight: bold;
        transform: translateY(-50%);
    }

    .badge {
        float: left; 
        font-weight: bold;
        position: relative;
        display: inline-block;
        background: $green;
        margin-right: 10px;
        padding: 0 10px;
        font-size: 16px;
        color: #fff;
    }

    p {
        //font-weight: bold;
        display: inline-block;
        margin: 0 10px;
        color:$text;

        span {
            text-decoration: line-through;
            font-weight: normal;
        }

    }

}


.admin-bar {
    .navbar {
        //top: 32px;
    }
}

.navbar {
    width: 100%;
    top: 0;
    z-index: 800;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background-color: $white;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);

    &.scroll {
        border-bottom: 1px solid $lightgrey;
        position: fixed;
    }
    .brand {
        display: block;
        float: left;
        padding: 20px 0 0;
        line-height: 1em;
        font-size: 25px;
        font-weight: 400;
        text-transform: uppercase;
        color: $link-dark;
        img {
            margin-top: -11px;
            height: 50px;
            float: left;
            margin-bottom: 5px;
        }
        span {
            font-weight: $f-demi-bold;
        }
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
    .usps {
        width: 100%;
        float: right;

        .usp {
            float: right;
            padding: 18px 0px 0px 25px;

            .fa {
                color: #107c17;
                font-size: 21px;
            }
        }
    }
    .navbar-main {
        display: block;
        float: right;
        width: calc(100% - 200px);

        .navbar-nav {
            margin-bottom: 0;
            li {
                display: inline-block;
                &.wpml-ls-menu-item {
                    a {
                        margin-left: 10px;
                    }
                }
                a {
                    font-size: 16px;
                    font-weight: $f-bold;
                    text-transform: uppercase;
                    line-height: 1em;
                    margin-left: 30px;
                    color: $link-dark;
                    display: inline-block;
                    padding: 25px 0;
                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: $link;
                    }
                }
            }
        }
    }

    .scroll_cta {
        width: 100%;
        float: left; 
        display: none;

        a {
            width: 100%;
            float: left;
            border-radius: 0px;
            text-align: center;
            padding: 10px 0px;
            font-size: 18px;
            font-weight: bold;
        }
    }
}

#nav-toggle {
    display: none;
    width: 30px;
    height: 30px;
    position: relative;
    float: right;
    margin: 27px 0;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $brand-primary;
        border-radius: 0px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 13px;
        }
        &:nth-child(4) {
            top: 26px;
        }
    }
    &.open {
        span {
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
            &:nth-child(2) {
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
            }
            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
}


/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
    .navbar {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
        //border-bottom: 1px solid #f5f5f5;
        .brand {
            font-size: 20px;
        }
        .navbar-main {
            display: block !important;
            margin: 0px;
            padding: 3px !important;

            .usps {
                background: #fff;
                margin: 0px;
                padding: 0px !important;

                .usp {
                    margin: 0px;
                    padding: 0px !important;
                    text-align: center;
                }
            }
            .navbar-nav {
                li {
                    a {
                        margin-left: 5px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .coupon--strip {
        b {
            font-size: 15px;
        }
        .button {
            font-size: 16px;
        }
    }
}

@include breakpoint(md) {
    #nav-toggle {
        display: block;
    }
    .navbar {
        .brand {
            width: 100%;
            text-align: center;
            img {
                margin-top: -5px;
                margin-bottom: -5px;
                float: none;
            }
        }
        .navbar-main {
            //border-top: 1px solid $lightgrey;
            width: 100%;
            padding: 25px 0;
            display: none;
            .navbar-nav {
                width: 100%;
                padding: 0;
                margin: 0;
                li {
                    width: 100%;
                    a {
                        padding: 10px 0;
                        margin-left: 0;
                        width: 100%;
                    }
                }
            }
        }
        #nav-toggle {
            margin: 18px 0;
        }
    }
    .coupon--strip {
        .badge {
            display: none;
        }
        b {
            margin: 0;
        }
        .button {
            position: relative;
            top: 0;
            transform: translateY(0);
            right: 0;
            margin-left: 10px;
        }
    }
}

@include breakpoint(sm) {
    #wpadminbar {
        position: fixed;
    }
    .admin-bar {
        .navbar {
            top: 46px;
        }
    }
    .navbar {
        .brand {
            padding: 15px 0;
            font-size: 18px;
            img {
                height: 35px;
            }
        }
        #nav-toggle {
            margin: 13px 0;
            span {
                height:2px;
            }
        }
    } 
    .coupon--strip {
        display: none;
    }

}