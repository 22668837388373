.takeover {

    .takeover-body-right,
    .takeover-body-left {
        top: 0;
        position: fixed;
        width: calc((100% - 1200px) / 2);
        height:100%;
        a {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
        }
    }

    .takeover-body-right {
        right: 0;
    }

    .takeover-body-left {
        left: 0;
    }

    .wrapper {
        max-width: 1200px;
        margin: 0 auto;
    }

    .navbar {
        max-width: 1200px;
    }

}

.banner_header {
    width: 100%;
    display: block;
    border-bottom: 1px solid $grey;
    text-align: center;
    background: #fff;

    img {
        width:100%;
        max-width: 100%;
        height: auto;
    }

    &.tablet {
        display: none;

        @include breakpoint(lg) {
            display: block;
        }

        @include breakpoint(sm) {
            display: none;
        }

    }

    &.mobile {
        display: none;

        @include breakpoint(sm) {
            display: block;
        }

        @include breakpoint(xs) {
            display: none;
        }
    }

    &.mobile-small {
        display: none;

        @include breakpoint(xs) {
            display: block;
        }
    }
}

.banner_home {
    width: 100%;
    display: block;
    border-bottom: 1px solid $grey;
    padding: 20px 10px;
    text-align: center;
    background: #fff;

    img {
        max-width: 100%;
        height: auto;
    }

    @include breakpoint(md) {
        display: none;
    }
}

.banner_review {
    width: 100%;
    display: block;
    box-shadow: 0 2px 4px 0 rgba(71,73,96,.09);
    padding: 20px 10px;
    text-align: center;
    margin-top: 20px;
    background: #fff;

    img {
        max-width: 100%;
        height: auto;
    }

    @include breakpoint(md) {
        display: none;
    }
}

.banner_review_bottom {
    width: 100%;
    display: block;
    padding: 20px 10px;
    text-align: center;

    &.top {
        margin-bottom: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    img {
        width: auto !important;
        max-width: 100%;
        height: auto;
    }

    @include breakpoint(md) {
        display: none;
    }
}

.banner_footer {
    width: 100%;
    display: block;
    border-top: 1px solid $grey;
    padding: 20px 10px;
    text-align: center;
    background: #fff;

    img {
        max-width: 100%;
        height: auto;
    }

    @include breakpoint(md) {
        display: none;
    }
}

.banner_toplist {

    &.mobile {
        display: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }
    
    @include breakpoint(md) {
        display: none;

        &.mobile {
            display: table-row;
        }
    }   
}

.banner_sidebar {
    margin-bottom: 20px;
    box-shadow: 0 2px 4px 0 rgba(71,73,96,.09);
    width: 100%;
    display: block;
    //padding: 20px 10px;
    text-align: center;
    background: #fff;

    img {
        max-width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 1600px) and (min-width: 767px) {

    .takeover {

        .takeover-body-right,
        .takeover-body-left {
            display: none;
        }

        .wrapper {
            max-width: 100%;
        }

        .navbar {
            max-width: 100%;
        }

    }

    .banner_header {

        img {
            width: auto;
        }

        &.tablet {
            display: block;
        }

    }

}