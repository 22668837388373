
/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
.page-template-template-home-v2 {
  .hero, .content {
    .container {
      max-width: 940px;
    }
  }

  .content {
    background: #fff;
  }
}
section {
  &.hero.homepagev2 {
    padding: 70px 0px;
    min-height: 800px;
    margin-bottom: 0px;

    .hero_content {
      width: 600px;
      margin-top: 0px !important;

      h1 {
        font-size: 50px;
        font-weight: bold;
        line-height: 48px;
        margin-bottom: 10px;
      }

      h2 {
        font-size: 21px;
        line-height: 32px;
      }
    }

    .partners {
      margin-top: 20px;
      .item {
        color: #000;
        width: 100%;
        float: left;
        margin-bottom: 10px;
        padding: 0px;
        border-radius: 3px;
        border: 0px;

        .score_mobile {
          display: none;
        }

        .inner {
          box-shadow: 0 10px 15px rgba(0,0,0,.25);
          padding: 0px;
          border: 0px !important;
          padding-top: 0px !important;
          border-radius: 0px !important;
        }

        .logo {
          width: 25%;
          height: auto;
          float: left;
          margin-top: 0px;

          .nummer {
            float: left;
            span {
              padding: 5px 10px;
              float: left;
              background: #a9a8a8;
              color: #fff;
              font-weight: bold;

              &.special {
                background: #f6e0e8;
                color: #000;
              }
            }
          }

          a {
            margin-top: 30px;
            float: left;
            width: 100%;

            img {
              max-height: 80px;
              max-width: 180px;
            }

            &.review {
              margin-bottom: 20px;
              margin-top: 20px;
              font-size: 14px;
            }
          }
        }

        .text {
          width: 30%;
          float: left;
          text-align: left;
          padding: 0px 20px;
          border-left: 1px solid #eee;

          h3 {
            margin: 20px 0px 10px 0px;
            font-weight: bold;
            font-size: 21px;
          }

          p {
            padding: 0px;
            margin: 0px 0px 20px 0px;
            font-size: 14px;
            line-height: 21px;
          }
        }

        .score {
          float: left;
          width: 20%;

          .nummer_score {
            font-size: 60px;
            font-weight: bold;
            line-height: 60px;
            margin: 40px 0px 10px 0px;
          }
        }

        .button_wrap {
          float: left;
          width: 25%;
          padding: 0px 20px;

          .button {
            margin-top: 60px;
          }
        }
      }
    }

    .information {
      width: 100%;
      float: left;
      display: none;
    }

    .more_info {
      width: 100%;
      float: left;

      .more_info_button {
        font-weight: bold;
        width: 100%;
        float: left;
        text-align: center;
        padding: 3px 0px;
        cursor: pointer;
        opacity: 0.8;
        font-size: 14px;

        &:hover {
          opacity: 1;
        }
      }

      .checklist {
        margin-top: 20px;
        position: relative;

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          margin-bottom: 0;
        }

        .leden {
          text-align: left;
          border: 1px solid #579712;
          float: left;
          border-radius: 20px;
          padding: 3px 10px;
          box-shadow: 0px 4px 5px rgba(57, 151, 18, 0.2);
          color: #579712;
          font-weight: bold;
          margin-top: 10px;
          font-size: 14px;
        }

        li {
          font-size: 0.875em;
          line-height: 1.2;
          margin-bottom: 15px;
          padding-left: 30px;
          text-align: left;

          &:before {
            content: "\f00c";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            left: 0;
            font-size: 18px;
            color: #107c17;
            vertical-align: middle;
          }

          &:last-child {
            margin-bottom: 0;
          }

        }

      }

      .button {
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
        margin: 0px 0px 20px 0px;
        left: 50%;
        position: relative;
        transform: translate3d(-50%, 0px, 0px);
      }

      .left, .right {
        width: 50%;
        padding: 20px;
        float: left;
        text-align:left;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

@include breakpoint(lg) {
  section {
    &.hero.homepagev2 {
      background-size: auto 600px !important;

      .partners {
        .item {
          .logo {
            width: 100%;
            a {
              float: right;
              width: auto;
              margin: 5px 10px;

              img {
                max-height: 30px;
              }
            }
            .review {
              display: none;
            }
          }

          .text, .score, .button_wrap {
            width: 30%;
            border-left: 0px;
          }

          .text {
            width: 40%;
          }
        }
      }
    }
  }

}

@include breakpoint(md) {
  .page-template-template-home-v2 {
    section {
      &.content {
        padding-top: 30px;
      }

      &.hero.homepagev2 {
        background-size: auto 900px !important;
        padding-top: 20px;

        .hero_content {
          width: 100%;

          h1 {
            color: #fff;
            font-size: 28px;
            line-height: 28px;
          }

          h2 {
            color: #fff;
            font-size: 18px;
            line-height: 21px;
          }
        }

        .partners {
          .item {
            background: #fff;

            .inner {
              background: #fff;
            }
            
            h3 {
              margin-top: 0px;
            }

            .logo {
              width: 100%;
              background: none;
              box-shadow:0px 0px 0px rgba(0, 0, 0, 0.0);
            
              a {
                float: right;
                width: auto;
                margin: 5px 10px;

                img {
                  margin: 5px 5px;
                  max-width: 135px;
                  max-height: 30px;
                  float: right;
                  position: relative;
                }
              }
              .review {
                display: none;
              }
            }
            .text, .score, .button_wrap {
              width: 100%;
              border-left: 0px;
            }

            .score {
              display: none;
            }

            .score_mobile {
              display: block;
              padding: 0px 20px;
              margin: 0px;

              .left {
                width: 50%;
                float: left;
              }

              .review {
                display: block;
                float: right;
                width: auto;
                font-size: 16px;
                margin-top: 15px;
              }

              .nummer_score {
                line-height: 40px;
                font-size: 32px;
                margin: 0px;
              }

              .rating {
                margin-top: -5px;
                font-size: 12px;
              }
            }

            .button_wrap {
              margin-bottom: 20px;

              .button {
                margin-top: 0px;
              }
            }

            .more_info {
              display: none;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(sm) {

}
