.related {

    header {
        height: auto;
        position: relative;
        margin: 5px 0 20px 0;
        transition: all 0.1s ease;
        &:hover,
        &:focus {
            opacity: 0.8;
        }
        &.imgLiquid_ready {
            padding-bottom: 50%;
        }
        img {
            width: 100%;
            height:auto;
        }
    }
    a {
        h4 {
            font-size: 1.125em;
            line-height: 1em;
            padding: 0;
            margin: 0;
            margin-bottom: 20px;
            margin-top: 20px;
            font-weight: 700;
            color: $brand-primary;
            transition: color .2s ease;

            &:hover,
            &:active {
               color: $link;
                text-decoration: none;
            }
        }

        &:hover,
        &:active {
            text-decoration: none;
        }
    }


}


/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {}

@include breakpoint(sm) {
    .related {
        .item {
            &.first {
                border-bottom: 1px solid $lightgrey;
                margin-bottom: 15px;
                padding-bottom: 10px;
            }
        }
    }
}
