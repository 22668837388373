.faq {
    .faq_list {
        background-color: $white;
        //border: 1px solid $lightgrey;
        padding: 30px 0px 20px 0px;
    }
    .toggle {
        &.first {
            .toggle-title {
                border-top: 0;
                i {
                    margin-top: 0;
                }
                .title-name {
                    padding-top: 0;
                }
            }
        }
        &:last-child {
            .toggle-inner {
                padding: 7px 0 10px 0;
                margin-bottom: 0;
            }
        }
        .toggle-title {
            position: relative;
            display: block;
            border-top: 1px solid $lightgrey;
            margin-bottom: 6px;
            cursor:pointer;
            h3 {
                font-size: 18px;
                margin: 0px;
                font-weight: bold;
                line-height: 1;
                cursor: pointer;
                font-weight: 200;
            }
            &.active h3 {}
        }
        .toggle-inner {
            padding: 7px 25px 10px 0;
            display: none;
            margin: -7px 0 6px;
            div {
                max-width: 100%;
            }
        }
        .toggle-title {
            .title-name {
                display: block;
                padding: 25px 0 14px;
                width: 90%;
                line-height: 1.2;
                font-weight: bold;
                //font-weight: 500;
            }
            a i {
                font-size: 22px;
                margin-right: 5px;
            }
            i {
                position: absolute;
                background: url('../images/plus_minus.png') 0px -24px no-repeat;
                width: 24px;
                height: 24px;
                transition: all 0.3s ease;
                margin: 25px 0 14px;
                right: 0;
            }
            &.active i {
                background: url('../images/plus_minus.png') 0px 0px no-repeat;
            }
        }
    }
    .main {
        padding-right: 0;
    }
}


/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {
    .faq {
        .main {
            padding-right: 15px;
            margin-bottom: 15px;
        }
    }
}

@include breakpoint(sm) {}
