.examples {

  .example {
    padding: 40px 0;

    h2 {
      margin-top: 0;
    }

    .tips {

      h3 {
        font-size: 21px;
        font-weight: bold;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          padding-left: 1.3em;
          font-size: 15px;

          &:before {
            content: "\f046";
            font-family: FontAwesome;
            display: inline-block;
            margin-left: -1.3em;
            width: 1.3em;
            color: $green;
          }

        }

      }

    }

    .images {
      text-align: center;

      img {
        border: 1px solid $brand-primary;
      }

    }

  }

  .example-1 {

    .images {

      img {
        max-width: 90%;
        width: 100%;
        height: auto;
      }

    }

  }

  .example-2 {
    background-color: $white;

    .images {

      img {
        max-width: 55%;
        width: 100%;
        height: auto;

        &:first-child {
          margin-bottom: 10px;
        }

      }

    }

  }

  .example-3 {

    .images {

      img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;

        &:first-child {
          margin-bottom: 10px;
        }

      }

    }

  }

}

.review-widget-generator {
  background-color: $white;
  padding: 40px 0;

  .generator-form {
    background-color: $brand-primary;
    color: $white;
    padding: 15px;
    box-shadow: 0 2px 4px 0 rgba(71, 73, 96, .09);

    h3 {
      margin-top: 0;
      font-weight: bold;
      margin-bottom: 15px;
    }

    textarea {
      color: #878787;
      line-height: 1.5;
    }

    #select-code {
      display: none;
    }

    .button {
      padding: 10px;
      font-size: 16px;
      margin-bottom: 5px;
    }

  }

}

.iframe-preview {
  padding: 40px 0;
  display: none;

  h3 {
    font-weight: bold;
    margin-top: 0;
  }

  .button {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .iframe {
    position: relative;
    width: 100%;
    height: 100%;

    .iframe-loader {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 50px;
      height: 50px;
      z-index: 0;
      background: url('../images/loader.gif') center center no-repeat;
      background-size: contain;
    }

    iframe {
      z-index: 1;
      position: relative;
      width: 100%;
      height: 200px;
    }

  }

}


@include breakpoint(lg) {

}

@include breakpoint(md) {
  .examples {

    .example {

      .images {
        text-align: left;
        margin-top: 40px;
      }

    }

    .example-2 {

      .images {

        img {
          max-width: 90%;
          width: 100%;
          height: auto;
        }

      }

    }

  }

}

@include breakpoint(sm) {

  .examples {

    .example {
      padding: 20px 0;

      .images {
        margin-top: 20px;
      }

    }

    .example-1,
    .example-2 {

      .images {

        .col-sm-6 {
          float: left;

          &:first-child {

            img {
              margin-bottom: 15px;
            }

          }

        }

        img {

          max-width: 235px;

        }

      }

    }

    .example-3 {

      .images {

        img {

          max-width: 600px;

        }

      }

    }

  }

}

@include breakpoint(xs) {

}