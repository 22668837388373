.contact-page {
    padding: 50px 0;
    h2,
    h3 {
        font-size: 24px;
        margin-top: 0;
    }
    .bedrijfs-gegevens {
        span {
            font-weight: bold;
            color: $brand-primary;
        }
    }
    .contact-gegevens {
        span {
            margin-right: 10px;
        }
    }
    .contact-form {
        .wpcf7-form-control-wrap {
            width: 100%;
        }
        .wpcf7-form-control {
            border: 0;
            border-radius: 0;
            outline: none;
            box-shadow: none;
            border-color: transparent;
            background-color: $grey;
            color: darken($text, 10%);
            height: auto;
            padding: 8px 15px;
            font-size: 15px;
            margin-bottom: 10px;
            width: 100%;
            box-shadow: inset 0 -15px 17px -10px rgba(0, 0, 0, .02);
            transition: background-color .2s ease;
            border: 2px solid #e5e5e5;
            &.wpcf7-submit {
                width: auto;
                background-color: $brand-primary;
                color: $white;
                float: right;
                &:hover,
                &:focus {
                    background-color: $link;
                }
            }
        }
        .wpcf7-submit {
            border: 0; 
        }
        textarea {
            resize: none;
        }
        div.wpcf7-response-output {
            margin: 30px 0 10px;
            width: 100%;
        }
        .wpcf7-validation-errors {
            border: 2px solid #e49a9a;
        }
        .wpcf7-not-valid-tip {
            display: none;
        }
        .wpcf7-not-valid {
            border: 2px solid #e49a9a;
        }
    }
    .contact-content {
        background-color: $white;
        border: 1px solid $lightgrey;
        padding: 15px 15px 5px 15px;
    } 
    .main {
        padding-right: 0;
    }
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $text;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $text;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: $text;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: $text;
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {
    .contact-page {
        .main {
            padding-right: 15px;
            margin-bottom: 15px;
        }
    }
}

@include breakpoint(sm) {}
