// Colors 
$brand-primary:         #43b4ec;
$brand-secondary:		rgba(255,0,106,1);
$text: 					#111111;
$bg-color: 				#f9f7f7;
  
$link: 					#36a0c7;
$link-dark: 			#162b32;

$footer-link:			#889ca3;
$copyrights-link:		#3b5a64;

$white:					#ffffff;
$grey:					#f1f1f1;
$lightgrey:				#f1f5f7;
$black:					#212121;
$green:					rgba(255,0,106,1);

// Global options
$enable-flex:           false;

//Font style
$fontSize: 				14px; 
$lineHeight: 			24px; 
$fontFamily: 			'Montserrat', sans-serif;
$fontCaption: 			'Montserrat', sans-serif;

$f-thin: 				100;
$f-extra-light: 		200;
$f-light: 				300;
$f-normal:				400; 
$f-medium: 				500;
$f-demi-bold: 			600;
$f-bold: 				700;
$f-heavy:				800;
$f-black: 				900;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;