footer {
    background-color: #fff;
    padding: 50px 0;
    width: 100%;
    float: left;
    box-shadow: 0 -3px 5px rgba(0,0,0,.08);

    .footer-aanbieders {
        width: 100%;
        float: left;
        margin-bottom: 40px;

        .item {
            float: left;
            width: 50%;
            padding: 10px 0px;
            border-bottom: 1px solid #eee;

            a {
                color: #000 !important;
                font-weight: bold;
            }
        }

        @include breakpoint(sm) {
            .item {
                width: 100%;
            }
        }
    }

    .secure {
        width: 100%;
        float: left;
        height: 50px;
        margin-top: 20px;
        
        img {
            float: left;
            height: 40px;
        }
    }

    .footer-nav {
        .nav_menu-2,
        .nav_menu-5 {
            width: 15%;
        }
        .related-4 {
            width: 40%;
        }
        .mc4wp_form_widget-2 {
            width: 30%;
        }
        .best-partner-for {
            ul {
                li {
                    width: 50%;
                }
            }
        }

        h3 {
            color: rgba(0, 0, 0, 0.8);
            font-weight: $f-demi-bold;
            margin-bottom: 20px;
            font-size: 26px;
        }
        nav {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        .navbar-nav {
            list-style-type: none;
            padding: 0;
            margin: 0;
            overflow: auto;
            li {
                float: left;
                width: 100%;
                margin-bottom: 8px;
                a {
                    color: rgba(0, 0, 0, 0.9);
                    font-size: 16px;
                    line-height: 26px;
                    span {
                        display: block;
                        .rating {
                            display: inline-block;
                        } 
                    }
                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: rgba(0, 0, 0, 0.8);
                    }
                }
            }
        }
        #services-footer {
            li {
                a {
                    &:before {
                        content: "";
                        padding-right: 0;
                    }
                }
            }
        }
        .item {
            .read_more {
                display: none;
            }
            a {
                color: $footer-link;
                h4 {
                    font-size: 16px;
                    line-height: 21px;
                }
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $link;
                }
            }
        }
        .social {
            margin-top: 20px;
            ul {
                width: 100%;
                float: left;
                list-style-type: none;
                padding: 0;
                margin: 0;
                li {
                    display: inline;
                    a {
                        font-size: 35px;
                        padding-right: 5px;
                        color: $footer-link;
                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: $link;
                        }
                    }
                }
            }
        }
    }

    .footer-reviews {
        ul {
            display: flex;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
        }
        li {
            background: #0f1e23;
            width: 18%!important;
            padding: 10px 20px;
            border-radius: 5px;
        }
    }

    .footer-newsletter {
        margin-left: -30px;
        background: #0f1f24;
        margin-right: -30px;
        padding: 10px 40px;
        margin-bottom: -20px;
        margin-top: 20px;
        text-align: center;
        @media (max-width: 1600px) {
            background: transparent;
        }
        form {
            width: 600px;
            margin: 0 auto;
            @media (max-width: 992px) {
                width: 100%;
            }
        }
    }

    .mailchimp {
        display: flex;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        .button {
            font: $f-medium 16px $fontCaption !important;
            height: 48px;
            margin-left: 10px;
        }

    }

}

.copyrights {
    padding: 10px 0;
    background-color: #fff;
    color: #000;
    font-size: 14px;
    width: 100%;
    float: left;
    border-top: 1px solid #eee;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            display: inline;
            a {
                display: block;
                color: #000;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
}


/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {}

@include breakpoint(sm) {
    footer {
        .secure {
            img {
                width: 100%;
                height: auto;
            }
        }
        .footer-nav {

            h3 {
                margin-bottom: 10px;
            }

            nav {
                margin-bottom: 30px;
            }

            .navbar-nav {

                li {
                    padding-bottom: 2px;
                    margin-bottom: 2px;
                }

            }

            &.last {

                nav {
                    margin-bottom: 0;
                }

            }

            .best-partner-for {

                ul {

                    li {
                        width: 100%;
                    }

                }

            }
        }
    }
    .copyrights {
        .pull-right {
            float: left !important;
        }
        ul {
            li {
                a {
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
        }
    }
}
