section {
  &.hero {
    display: block;
    width: 100%;
    padding: 100px 0 0px;
    margin: 0;
    color: $white;
    text-align: left;
    position: relative;
    margin-bottom: 100px;

    &.mobile_header {
      display: none;
    }

    @media(max-width: 1200px){
      &.mobile_header {
        display: block;
      }
    }

    .extra_add {
      background: #f5eda9;
      width: 100%;
      float: left;
      border-radius: 5px;
      color: #494310;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: 300;
      margin-top: 10px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);

      .fa {
        float: right;
        margin-top: 5px;
        font-size: 16px;
      }

      span {
        font-weight: bold;
        text-decoration: underline;
      }

      &:hover {
        text-decoration: none;
        background: darken(#f5eda9, 5);
      }
    }

    .users_online {
      display: none;
    }
    .ribbon {
      display: none;
    }
    .top, .bottom {
      width: 100%;
      float: left;
    }

    .mobile_hero_image {
      display: none;
    }

    .hero_content {
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      h1, .title{
        font-size: 32px;
        line-height: 1.2;
        font-weight: bold;
        //color: #000;
        margin: 0;
        display: block;
        padding-bottom: 5px;
      }

      h2, .subtitle {
        font-size: 1.500em;
        line-height: 1.2;
        //color: #000;
        padding-bottom: 22px;
        margin: 0;
        display: block;
      }

    }

    .user-info {
      position: absolute;
      left: 0;
      top: 0;
      padding: 3px 15px;
      background: #fff;
      color: $brand-primary;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
      width:100%;
      border-top: 1px solid #edf4f5;

      ul {
        padding: 0;
        margin: 0;
        text-align: right;

        li {
          display: inline-block;
          list-style: none;
          font-size: 12px;
          font-weight: bold;
          text-align: left;
          margin-left: 0;
          padding: 0 10px;

          &:first-child {
            border-top: none;
          }

          &:last-child {
            padding-right: 0;
          }

          .fa-spin {
            font-size: 12px;
          }

        }

      }

      p {
        margin: 0;
      }

    }

  }

  .partners {
    width: 100%;
    margin-top: 100px;
    margin-bottom: -75px;
    float: left;

    .item {
      width: 20%;
      float: left;
      padding: 0px 10px;
      text-align: center;

      .score_mobile, .review_mobile, .subline_button {
        display: none;
      }

      p {
        padding: 15px 0px;
        width: 100%;
        float: left;
        line-height: 26px;
        font-size: 16px; 
        color: $text;
        margin: 0px;
      }

      .inner {
        background: #fff;
        border-radius: 5px;
        padding: 20px 5px;
        float: left;
        width: 100%;
        box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.1);

        &.populair-inner {
          border-radius: 0px 0px 5px 5px;
        }
      }

      .populair {
        background: #f11261;
        padding: 5px 0px;
        text-decoration: center;
        float: left;
        width: 100%;
        font-weight: bold;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
        border-radius: 5px 5px 0px 0px;
        margin-top: -36px;

        .fa {
          //color: #ff006a;
        }

        &.favoriet {
          background: rgba(255, 255, 255, 0.2);
        }
      }

      .button {
        padding: 10px 0px;
        font-size: 18px;
        width: 100%;
        float: left;
        font-weight: bold;

        .fa {
          padding: 10px 7px;
        }
      }

      .logo {
        height: 40px;
        margin-bottom: 5px;

        img {
          max-height: 40px;
          max-width: 150px;
        }
      }

      .review {
        width: 100%;
        float: left;
        color: $text;
        text-decoration: underline;
        font-size: 12px;
        margin-top: 10px;

      }
    }
  }

}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {
  section {
    &.hero {
      .hero_content {
        h1 {
          font-size: 3em;
        }
      }

      .partners {
        .item {
          .inner {
            padding: 15px 10px;
          }
        }
      }
    }
  }
}

@include breakpoint(md) {
  section {
    &.hero {
      margin-bottom: 20px;
      padding: 25px 0 25px 0px;
      .hero_content {
        text-align: center;

        h1 {
          font-size: 2em;
        }
      }
      .partners {
        margin: 0px;
      }
      .user-info {
        text-align: center;
    	padding: 5px 0;
      }
      .partners {
        .item {
          width: 50%;
          margin-bottom: 10px;

          .populair {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

@include breakpoint(sm) {
  .page-template-template-home {
    section.hero {
      background: none !important;
    }
  }
  section {
    &.hero {
      position: relative;
      z-index: 10;
      margin-bottom: 0px;

      .hero_content {
        margin-top: 20px;
      }
      //padding: 90px 0 230px;

      .users_online {
        display: block;
        text-align: center;
        border: 2px solid #29a954;
        float: left;
        border-radius: 10px;
        padding: 3px 10px;
        box-shadow: 0 4px 5px rgba(57,151,18,.2);
        color: #29a954;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        position: relative;
        opacity: 0;
        margin-top: -30px;
        transition: all 1.5s ease;
        z-index: 1;

        &.show {
          margin-bottom: 15px;
          margin-top: -5px;
          opacity: 1;
        }

        .fa {
          position: absolute;
          left: 50%;
          font-size: 60px;
          margin-left: -12px;
          top: -43px;
          text-shadow: 0 4px 5px rgba(57,151,18,.2);
        }

        .round{
          width: 15px;
          height: 15px;
          border-radius: 20px;
          background: #29a954;
          display: inline-block;
          position: relative;
          top:2px;
          right: 5px;
          box-shadow: 0 0 0 0 rgba(57,151,18,1);
          transform: scale(1);
          animation: pulse 2s infinite;
        }

        @keyframes pulse {
          0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(57,151,18, 0.7);
          }

          70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(57,151,18, 0);
          }

          100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(57,151,18, 0);
          }
        }
      }

      .hero_content {
        position: relative;
        padding-bottom: 25px;
        margin-top: 5px;

        h1, .title {
          text-align: left;
          font-size: 28px; 
          font-weight: bold;
          z-index: 12;
          position: relative;
          padding-right: 60px;
          //margin-bottom: 20px;
          color: #000;

          strong {
            font-weight: bold;
          }
        }
        h2, .subtitle {
          z-index: 12;
          position: relative;
          font-size: 18px;
          padding-right: 120px;
          text-align: left;
          padding-bottom: 10px;
          color: #000;
        }
      }
      .mobile_hero_image {
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 10;
        display: block;

        img {
          width: auto;
          height: 155px;
        }
      }
      .favoriet {
        display: none;
      }
      .button {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
        //border-radius: 5px 5px 0px 0px;

        .fa {
          float: right;
          padding: 10px 15px;
          margin: -10px 0px;
          background: rgba(255, 255, 255, 0.2);
          font-size: 18px;
        }
      }
      .subline_button {
          float: left;
          width: 100%;
          padding: 0px;
          text-align: center;
          //font-weight: 700;
          background: #cee8f7;
          color: #04446b;
          margin-top:10px;
          font-weight: bold;
          border-top: 0;
          border-radius: 0px;
          font-size: 12px;
          display: block !important;
          text-shadow: 0px 1px 1px solid rgba(0, 0, 0, 0.8);

          &:hover {
            text-decoration: none;
            font-weight: bold;
          }
      }

      .score_mobile {
        width: 100%;
        float: left;
        color: #000;
        display: block !important;
        text-align: left;
        margin: 5px 0px;

        a {
          float: right;
          font-size: 14px;
          text-decoration: underline;
          color: #000;
        }

        span {
          font-size: 28px;
          font-weight: bold;
        }
      }

      .partners {
        position: relative;
        .item {
          padding: 0px;
          width: 100%;
          margin-bottom: 15px;
          //border: 2px solid #f11261;
          border-radius: 10px;
          //overflow: hidden;
          position: relative;
          box-shadow:0px 4px 10px rgba(0, 0, 0, 0.25);
          z-index: 2;

          .populair {
            display: none;
          }

          .logo {
            margin-bottom: 0;
            position: relative;
            width: 100%;
            height: 70px;
            background: #fff;
            margin-top: 20px;
            margin-bottom: 5px;
            border-radius: 4px;
            padding: 3px;
            background: #fff;
            //box-shadow:0px 3px 5px rgba(0, 0, 0, 0.2);
            //box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);

            img {
              max-height: 95%;
              max-width: 95%;
              width: auto;
              height: auto;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }

          .review {
            display: none;
          }

          .review_mobile {
            display: block !important;
            font-size: 12px;
            color: #000;
            margin-top: -5px;
          }

          .inner {
            border-radius: 10px;
            border: 2px solid #ff006a;
            padding: 0px;
            overflow: hidden;
            //background: #f5f5f5;
          }

          .top {
            width: 40%;
            min-height: 195px;
            //height: 75px;
            margin-bottom: 0px;
            border-right: 1px dashed #d2d2d2;
            padding: 20px 1px;
            //background: #f6f6f6;
          }

          .bottom {
            min-height: 135px;
            width: 60%;
            margin-bottom: 3px;
            padding: 15px 10px 5px 10px;
            background: #fff;

            p {
              //padding: 0px 10px;
              font-size: 14px;
              line-height: 21px;
              margin-top: 0px;
              padding-top: 0px;
              padding-bottom: 0px;
              text-align: left;
            }
          }

          .button {
            padding: 8px 0;
            font-size: 13px;
          }

          .review {
            margin-top: 2px;
          }
        }
        /* common */
        .ribbon {
          width: 150px;
          height: 150px;
          overflow: hidden;
          position: absolute;
          display: block;
          z-index: 10;
          text-decoration: none;
          color: #fff;
        }
        .ribbon::before,
        .ribbon::after {
          position: absolute;
          z-index: -1;
          content: '';
          display: block;
          border: 5px solid #2980b9;
        }
        .ribbon span {
          position: absolute;
          display: block;
          width: 170px;
          padding: 5px 0;
          background-color: #2991d0;
          //box-shadow: 0 5px 10px rgba(0,0,0,.1);
          color: #fff;
          font-weight: bold;
          font-size: 9px;
          text-shadow: 0 1px 1px rgba(0,0,0,.2);
          text-transform: uppercase;
          text-align: center;
          left: -38px;
        }
        .ribbon.favo span {
          background: #ff006a;
        }
        .ribbon.favo::after, .ribbon.favo::before {
          border: 5px solid #d6055c;
        }
        /* top left*/
        .ribbon-top-left {
          top: -10px;
          left: -10px;
        }
        .ribbon-top-left::before,
        .ribbon-top-left::after {
          border-top-color: transparent;
          border-left-color: transparent;
        }
        .ribbon-top-left::before {
          top: 0;
          right: 42px;
        }
        .ribbon-top-left::after {
          bottom: 42px;
          left: 0;
        }
        .ribbon-top-left span {
          right: -25px;
          top: 20px;
          transform: rotate(-45deg);
        }

      }
      .user-info {
	      ul {
	      text-align: center;
	      }
      	.hidden-sm {
      		display: none;
      	}
      }
    }
  }
}

.desktop_wrapper {
  @media(max-width: 1200px){
    display: none;
  }
  .button_wrap {
    width: 100%;
    float: left;
  }
  .header {
    padding: 75px 0px;
    color: #000;
    border-bottom: 4px solid #f11261;
    margin-bottom: 30px;

    .container {
      padding-right: 475px;
    }

    h1 {
      color: rgba(0, 0, 0, 0.9);
      font-size: 26px;
    }
  }

  .hero_content {
    padding: 30px 0px 0px 0px;
    width: 100%;
    float: left;
  }

  h1, .title{
    font-size: 32px;
    line-height: 1.2;
    font-weight: bold;
    color: #111111;
    margin: 0;
    display: block;
    padding-bottom: 5px;
  }

  h2, .subtitle {
    font-size: 1.500em;
    line-height: 1.2;
    color: #111111;
    padding-bottom: 10px;
    margin: 0;
    display: block;
  }

  h4 {
    width: 100%;
    float: left;
    font-weight: bold;
  }

  .partners {
    width: 100%;
    float: left;

    .usps {
      padding: 0px 0px 0px 0px;
      font-size: 14px;
      font-weight: normal;
      list-style: none;

      li {
        width: 100%;
        padding-left: 15px;
        float: left;
        position: relative;
      }
    }

    .usps li::before {
      content: "\2022";
      color: #f11261;
      font-weight: bold;
      display: inline-block; 
      font-size: 24px;
      left: 0px;
      top: -3px;
      position: absolute;
    }

    .item {
      width: 100%;
      float: left;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
      background: #fff;
      margin-bottom: 5px;
      padding: 20px 20px 5px 20px;
      position: relative;
      border-radius: 4px;

      &.first_item {
        background: #fcf2f6;
        border: 1px solid #f11261;
        box-shadow: 0px 3px 15px rgba(241, 18, 97, 0.2);

        .logo {
          .nummer {
            background: #f11261;
            color: #fff;
            box-shadow: 0px 3px 15px rgba(241, 18, 97, 0.2);
          }
        }
      }
    }
    .inner {
      float: left;
      width: 100%;
    }
    .logo {
      width: 250px;
      height: 120px;
      margin-right: 20px;
      float: left;

      .nummer {
        width: 40px;
        height: 40px;
        background: #eee;
        text-align: center;
        line-height: 40px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        float: left;
        padding: 0px;
        border-radius: 4px;
        color: #000;
        margin: -10px;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      }

      a {
        width: 100%;
        height: 100%;
        float: left;
        position: relative;

        img {
          max-height: 30px;
          max-width: 90%;
          position: absolute;
          bottom: 10px;
          left: 10px;
        }
      }
    }
    .score {
      float: left;
      width: 250px;
      padding-right: 20px;

      h3 {
        font-weight: bold;
        font-size: 21px;
        margin: 0px 0px 3px 0px;
      }
    }

    .score_big {
      float: left;
      width: 80px;
      text-align: center;
      margin-top: 32px;
      color: #2991d0;

      span {
        font-size: 40px;
        font-weight: bold;
        width: 100%;
        float: left;
        margin-bottom: 10px;
      }

      a {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        text-decoration: underline;
        width: 100%;
        float: left;
      }
    }

    .score-wrap {
      float: left;

      .rating {
        float: left;
      }

      .review_mobile {
        float: left;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        text-decoration: underline;
        margin-left: 10px;
      }
    }

    .onliner {
      width: 100%;
      float: left;
      margin: 0px;
      font-size: 14px;
    }

    .ribbon {
      position: absolute;
      right: -5px;
      top: -10px;
      padding: 0px 10px;
      font-size:16px;
      background: #36a0c7;
      color: #fff;
      font-weight: bold;
      border-radius: 5px;
      text-decoration: none !important;
    }

    .bottom {
      float: right;
      padding-left: 20px;
      width: 205px;

      .button {
        font-size: 14px;
        padding: 10px 10px;
        text-align: center;
        float: right;
        margin-top: 38px;
        width: 100%;
        box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.24);
      }

      .subline_button {
        float: left;
        width: 100%;
        padding: 0;
        text-align: center;
        background: #cee8f7;
        color: #04446b;
        margin-top: 10px;
        font-weight: 700;
        border-top: 0;
        border-radius: 0;
        font-size: 10px;
        display: block!important;
        text-shadow: 0 1px 1px solid rgba(0,0,0,.8);
      }
    }
  }

  .more_info {
    width: 100%;
    float: left;
    border-top: 1px solid #eee;
    margin-top: 15px;

    .information {
      width: 100%;
      float: left;
      display: none;
    }

    .more_info_button {
      font-weight: bold;
      width: 100%;
      float: left;
      text-align: center;
      padding: 3px 0px;
      cursor: pointer;
      opacity: 0.8;
      font-size: 14px;

      &:hover {
        opacity: 1;
      }
    }

    .checklist {
      margin-top: 20px;
      position: relative;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-bottom: 0;
      }

      .leden {
        text-align: left;
        border: 1px solid #579712;
        float: left;
        border-radius: 20px;
        padding: 3px 10px;
        box-shadow: 0px 4px 5px rgba(57, 151, 18, 0.2);
        color: #579712;
        font-weight: bold;
        margin-top: 10px;
        font-size: 14px;
      }

      li {
        font-size: 0.875em;
        line-height: 1.2;
        margin-bottom: 15px;
        padding-left: 30px;
        text-align: left;

        &:before {
          content: "\f00c";
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: absolute;
          left: 0;
          font-size: 18px;
          color: #107c17;
          vertical-align: middle;
        }

        &:last-child {
          margin-bottom: 0;
        }

      }

    }

    .button {
      padding-left: 20px;
      padding-right: 20px;
      width: auto;
      margin: 0px 0px 20px 0px;
      left: 50%;
      position: relative;
      font-size: 16px;
      padding: 10px 20px;
      transform: translate3d(-50%, 0px, 0px);
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    }

    .left, .right {
      width: 50%;
      padding: 20px;
      float: left;
      text-align:left;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .berichten {
    h3 {
      font-weight: bold;
      font-size: 18px;
    }

    .related {
      border: 0px;
      padding-bottom: 0px;

      header {
        margin: 0px;
      }

      .item-content {
        padding: 0px;
      }

      .item {
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: 10px;
        background: #fff;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
      }

      .item-content h4, .date {
        float: none;
        margin: 0px;
        color: #000;
        padding: 10px;
        width: 100%;
        float: left;
        font-size: 16px;
      }

      .item-content h4 {
        padding-bottom: 0px;
      }

      .date {
        font-size: 14px;
        font-weight: 300;
        padding-top: 0px;
      }
    }
  }
}